import { memo, useContext } from "react";
import PropTypes from "prop-types";
import { FormattedMessage } from "react-intl";
import RelativeLink from "app/pages/.shared/RelativeLink";
import { brandPropTypes } from "app/utils/propTypes";
import { BRANDS } from "app/constants";
import DotNotification from "app/pages/.shared/DotNotification/DotNotification";
import IconAccount from "app/pages/.shared/IconAccount/IconAccount";
import { IconCredit, IconHelp } from "app/pages/.shared/static/icons";
import AmountContainer from "app/pages/.shared/AmountContainer";
import NewBadge from "app/pages/Header/conditionnalComponents/NewBadge";
import AccountMenuFooterContainer from "app/pages/Header/AccountMenu/AccountMenuFooter/AccountMenuFooterContainer";
import Typography, { TYPOGRAPHY_VARIANTS } from "app/pages/.shared/Typography/Typography";
import ShopSwitcher from "app/pages/Home/ShopSwitcher/ShopSwitcher";
import ShopSwitcherContainer from "app/pages/Home/ShopSwitcher/ShopSwitcherContainer";
import AppGlobalsContext from "app/AppGlobalsContext";
import { RESOLUTION } from "app/pages/.shared/responsive/responsiveReducer";
import { useLocation } from "react-router-dom";

const AccountMenuAuthenticated = ({
	enableSponsorship,
	logout = () => {},
	partnerCode,
	brand,
	showCustomerRequestNotification,
	onItemClick = () => {},
	isWhiteLabel,
	enableCredits,
	creditsAmount,
	creditNotesAmount,
	shouldDisplayCreditNotesEntry = false,
	isIdentified = false,
}) => {
	const isBrandXX = brand === BRANDS.XX;
	const { resolution } = useContext(AppGlobalsContext);

	const isMobile = resolution === RESOLUTION.SMALL || resolution === RESOLUTION.MEDIUM;
	const { pathname } = useLocation();

	const shouldDisplayShopSwitcher =
		!pathname.includes("/booking/search") &&
		!pathname.includes("/booking/quote") &&
		!pathname.includes("/booking/payment") &&
		!pathname.includes("/booking/auth") &&
		!pathname.includes("/booking/confirmation");
	return (
		<div
			className="account-menu__modal account-menu--authenticated"
			data-testid="account-menu-authenticated"
		>
			<nav className="account-menu__body">
				{shouldDisplayShopSwitcher && isMobile && (
					<div className="account-menu__section">
						<ShopSwitcherContainer
							renderComponent={props => <ShopSwitcher {...props} />}
						/>
					</div>
				)}
				<div className="account-menu__section">
					<div className="account-menu__head-item">
						<div className="account-menu__head-icon">
							<IconAccount />
						</div>

						<Typography
							variant={TYPOGRAPHY_VARIANTS.LARGE}
							isBold
							className="account-menu__head-title"
						>
							<FormattedMessage id="header.nav.item.my.account" />
						</Typography>
					</div>

					<div className="account-menu__list">
						{!isBrandXX && (
							<RelativeLink
								to={{ pathname: "/account/profile" }}
								onClick={onItemClick}
								data-testid="account-menu-profile-item"
								className="account-menu__item"
							>
								<FormattedMessage id="menu.item.my.profile" />
							</RelativeLink>
						)}

						{enableCredits && !isIdentified && (
							<RelativeLink
								to={{ pathname: "/account/mycredits" }}
								onClick={onItemClick}
								data-testid="account-menu-credits-item"
								className="account-menu__item account-menu__link account-menu__item--chip"
							>
								<FormattedMessage
									id={
										creditsAmount > 0
											? "menu.item.my.credits.with.amount"
											: "menu.item.my.credits"
									}
									values={{
										creditAmount: (
											<div className="account-menu__chip account-menu__chip--credit">
												<IconCredit width={14} height={17} />
												<AmountContainer amount={creditsAmount} />
											</div>
										),
									}}
								/>
							</RelativeLink>
						)}

						{shouldDisplayCreditNotesEntry && !isIdentified && (
							<RelativeLink
								onClick={onItemClick}
								to={{ pathname: "/account/myrefunds" }}
								className="account-menu__item account-menu__item--chip"
							>
								<FormattedMessage
									id={
										creditNotesAmount > 0
											? "menu.item.my.refunds.with.amount"
											: "menu.item.my.refunds"
									}
									values={{
										refundAmount: (
											<div className="account-menu__chip account-menu__chip--refund">
												<AmountContainer amount={creditNotesAmount} />
											</div>
										),
									}}
								/>
							</RelativeLink>
						)}

						{enableSponsorship && (
							<RelativeLink
								to={{ pathname: "/account/sponsorship" }}
								onClick={onItemClick}
								data-testid="account-menu-sponsorship-item"
								className="account-menu__item"
							>
								<FormattedMessage id="menu.item.sponsorship" />
								<NewBadge partnerCode={partnerCode} />
							</RelativeLink>
						)}

						{isWhiteLabel && !isBrandXX && (
							<RelativeLink
								to={{ pathname: "/account/security" }}
								onClick={onItemClick}
								data-testid="account-menu-security-item"
								className="account-menu__item"
							>
								<FormattedMessage id="menu.item.security" />
							</RelativeLink>
						)}

						{!isBrandXX && (
							<RelativeLink
								to={{ pathname: "/account/mybookings" }}
								onClick={onItemClick}
								data-testid="account-menu-mybookings-item"
								className="account-menu__item"
							>
								<FormattedMessage id="menu.item.my.bookings" />
							</RelativeLink>
						)}

						{isWhiteLabel && !isBrandXX && (
							<RelativeLink
								to={{ pathname: "/account/subscriptions" }}
								onClick={onItemClick}
								data-testid="account-menu-subscriptions-item"
								className="account-menu__item"
							>
								<FormattedMessage id="menu.item.my.subscriptions" />
							</RelativeLink>
						)}

						{!isIdentified && (
							<RelativeLink
								to={{ pathname: "/" }}
								onClick={() => logout()}
								data-testid="account-menu-logout-item"
								className="account-menu__item"
							>
								<FormattedMessage id="menu.item.logout" />
							</RelativeLink>
						)}
					</div>
				</div>

				<div className="account-menu__section">
					<div className="account-menu__head-item">
						<div className="account-menu__head-icon">
							<IconHelp />
						</div>

						<Typography
							variant={TYPOGRAPHY_VARIANTS.LARGE}
							isBold
							className="account-menu__head-title"
						>
							<FormattedMessage id="menu.item.need.help" />
						</Typography>
					</div>

					<div className="account-menu__list">
						<RelativeLink
							to={{ pathname: "/account/faq-contact" }}
							onClick={onItemClick}
							data-testid="account-menu-faq-contact-item"
							className="account-menu__item"
						>
							<FormattedMessage id="menu.item.faq.contact" />
						</RelativeLink>

						<RelativeLink
							to={{ pathname: "/account/customer-request/my-requests" }}
							onClick={onItemClick}
							data-testid="account-menu-customer-request-item"
							className="account-menu__item"
						>
							<FormattedMessage id="menu.item.my.requests" />
							{showCustomerRequestNotification && <DotNotification />}
						</RelativeLink>
					</div>
				</div>
			</nav>

			<AccountMenuFooterContainer onItemClick={onItemClick} isAuthenticated={true} />
		</div>
	);
};

AccountMenuAuthenticated.propTypes = {
	brand: brandPropTypes,
	creditsAmount: PropTypes.number,
	creditNotesAmount: PropTypes.number,
	partnerCode: PropTypes.string,
	showCustomerRequestNotification: PropTypes.bool,
	isWhiteLabel: PropTypes.bool,
	enableCredits: PropTypes.bool,
	shouldDisplayCreditNotesEntry: PropTypes.bool,
	enableSponsorship: PropTypes.bool,
	isIdentified: PropTypes.bool,
	logout: PropTypes.func,
	onItemClick: PropTypes.func,
};

export default memo(AccountMenuAuthenticated);
