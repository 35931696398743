import { memo, useContext } from "react";
import PropTypes from "prop-types";
import { FormattedMessage } from "react-intl";
import Timer from "app/pages/.shared/Timer";
import Badge from "app/pages/.shared/Badge";
import BadgeList from "app/pages/.shared/ColoredProductBadge/BadgeList";
import AppGlobalsContext from "app/AppGlobalsContext";
import "./ProductTopBar.scss";
import { BRANDS } from "app/constants";

const ProductTopBar = ({
	isFlashsale = {},
	badges = [],
	productExpirationHours,
	productExpirationDays,
	hideSuffix = false,
}) => {
	const { brand } = useContext(AppGlobalsContext);
	const hideTimer = brand === BRANDS.SV || brand === BRANDS.TK;

	return (
		<div className="product-top-bar" data-testid="product-top-bar">
			{isFlashsale && !hideTimer && (
				<Badge>
					<Timer
						hour={productExpirationHours}
						day={productExpirationDays}
						suffix={<FormattedMessage id="timer.left.label" />}
						hideSuffix={hideSuffix}
					/>
				</Badge>
			)}
			{badges.length > 0 && (
				<div className="product-top-bar__badges">
					<BadgeList badges={badges} />
				</div>
			)}
		</div>
	);
};

ProductTopBar.propTypes = {
	isFlashsale: PropTypes.bool,
	productExpirationHours: PropTypes.number,
	badges: PropTypes.array,
	productExpirationDays: PropTypes.number,
	hideSuffix: PropTypes.bool,
};

export default memo(ProductTopBar);
