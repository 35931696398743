import PropTypes from "prop-types";
import { memo, useCallback, useContext, useState } from "react";
import "./ShopSwitcher.scss";

import Typography, { TYPOGRAPHY_VARIANTS } from "app/pages/.shared/Typography/Typography";
import { getCurrencySymbol, getShopDefaultCurrency } from "app/utils/utils";
import AppGlobalsContext from "app/AppGlobalsContext";
import { RESOLUTION } from "app/pages/.shared/responsive/responsiveReducer";
import ShopSwitcherDetailModalContainer from "app/pages/Home/ShopSwitcher/ShopSwitcherDetailModalContainer";
import IconRight from "app/pages/.shared/static/icons/IconRight";
import ExternalCurrentColorSVG from "app/pages/.shared/ExternalCurrentColorSVG/ExternalCurrentColorSVG";

const ShopSwitcher = ({ currentShop = "", currencyCode = "", shops = [] }) => {
	const { resolution } = useContext(AppGlobalsContext);

	const isMobile = resolution === RESOLUTION.MEDIUM || resolution === RESOLUTION.SMALL;

	const [isOpen, setIsOpen] = useState(false);

	const [, currentCountryCode] = currentShop.split("-");

	const handleClick = useCallback(() => {
		setIsOpen(!isOpen);
	}, [isOpen]);

	const hideSwitcherModal = useCallback(() => {
		setIsOpen(false);
	}, []);

	const activeShopObject = shops.find(shop => shop.code === currentShop);

	const currencyShop = currencyCode
		? {
				code: currencyCode,
				symbol: getCurrencySymbol(activeShopObject?.code, currencyCode),
		  }
		: getShopDefaultCurrency(activeShopObject);

	const titleToDisplay = isMobile
		? `${activeShopObject?.label} | ${currencyShop?.code} - ${currencyShop?.symbol || ""}`
		: currencyCode || "";

	const isInternationalCountry = currentCountryCode?.includes("WW");

	return (
		shops.length > 1 && (
			<div
				className="shop-switcher"
				data-testid="shop-switcher"
				{...isMobile && { onClick: handleClick }}
			>
				<div className="shop-switcher__content" onClick={handleClick}>
					<div className="shop-switcher__button">
						{isInternationalCountry ? (
							<ExternalCurrentColorSVG svgUrl="/static/icons/icon-flag-WW.svg" />
						) : (
							<i className={`icon icon--flag-${currentCountryCode}`} />
						)}
					</div>
					<Typography
						className="shop-switcher__current-shop"
						variantMD={TYPOGRAPHY_VARIANTS.SMALL}
						variant={TYPOGRAPHY_VARIANTS.LARGE}
						isBold
					>
						{titleToDisplay}
					</Typography>
				</div>
				{isMobile && <IconRight height={18} width={18} onClick={handleClick} />}
				<ShopSwitcherDetailModalContainer
					isOpened={isOpen}
					close={hideSwitcherModal}
					hideSwitcherModal={hideSwitcherModal}
				/>
			</div>
		)
	);
};

ShopSwitcher.propTypes = {
	currentShop: PropTypes.string,
	currencyCode: PropTypes.string,
	shops: PropTypes.arrayOf(
		PropTypes.shape({
			code: PropTypes.string.isRequired,
			currencyCode: PropTypes.string.isRequired,
			language: PropTypes.string.isRequired,
		})
	),
};

export default memo(ShopSwitcher);
