import { UPDATE_CURRENCY } from "app/reducers/currencyActionTypes";
const initialState = "";
export default (currency = initialState, action) => {
	switch (action.type) {
		case UPDATE_CURRENCY: {
			return action?.currencyCode || "";
		}
		default:
			return currency;
	}
};
