import { memo, useContext, useEffect, useMemo } from "react";
import PropTypes from "prop-types";
import { FormattedMessage } from "react-intl";
import Button from "app/pages/.shared/form/Button";
import { useFormikContext } from "formik";
import AdvancedSelectFormik from "app/pages/.shared/form/AdvancedSelectFormik/AdvancedSelectFormik";
import Typography, { TYPOGRAPHY_VARIANTS } from "app/pages/.shared/Typography/Typography";
import "./ShopSwitcherDetailContent.scss";
import CountryInputValueFormat from "app/pages/Home/ShopSwitcher/CountryInputValueFormat";
import {
	getCurrencySymbol,
	getShopAvailableCurrencies,
	getShopDefaultCurrency,
	getShopLocalizedLanguage,
	groupShopsByCountry,
} from "app/utils/utils";
import AppGlobalsContext from "app/AppGlobalsContext";
import { RESOLUTION } from "app/pages/.shared/responsive/responsiveReducer";
import useVisualViewport from "app/utils/hooks/useVisualViewport";
import uniqBy from "lodash/uniqBy";
import AlertMessage from "app/pages/.shared/AlertMessage";

const MENU_LIST_MAX_HEIGHT_MOBILE = 280;
const MENU_LIST_MAX_HEIGHT_DESKTOP = 364;
const MENU_PORTAL_MARGIN = 0;
const POPPER_WIDTH = 279;
const MENU_MARGIN_TOP = 0;
const MENU_LIST_PADDING = ["0 0 12px 0", "0"];
const MENU_BORDER = "1px solid var(--color-gray-500, #CCC)";
const MENU_BOX_SHADOW = "0px 2px 4px 0px rgba(0, 0, 0, 0.10)";

const ShopSwitcherDetailContent = ({ shops = [], currentShop = "", currentCurrency = "" }) => {
	const { resolution } = useContext(AppGlobalsContext);
	const { values, setFieldValue, isSubmitting } = useFormikContext();
	const [, hasKeyboard] = useVisualViewport();

	const isMobile = resolution === RESOLUTION.MEDIUM || resolution === RESOLUTION.SMALL;

	const menuListMaxHeight = isMobile ? MENU_LIST_MAX_HEIGHT_MOBILE : MENU_LIST_MAX_HEIGHT_DESKTOP;

	const groupedShops = useMemo(() => groupShopsByCountry(shops), [shops]);

	const countries = groupedShops.map(group => ({
		label: group.country.label,
		code: group.country.code,
	}));

	const selectedCountry = values?.country;
	const selectedLanguage = values?.language;
	const selectedCurrency = values?.currency;

	const [currentLang, currentCountry] = currentShop.split("-");

	const languageChanged = selectedLanguage?.code && selectedLanguage?.code !== currentLang;
	const countryChanged = selectedCountry?.code && selectedCountry?.code !== currentCountry;

	const availableLanguages = selectedCountry
		? uniqBy(
				(
					groupedShops.find(group => group.country.code === selectedCountry.code)
						?.availableShops || []
				).map(shop => getShopLocalizedLanguage(shop)),
				"code"
		  )
		: [];
	const availableCurrencies = selectedCountry
		? uniqBy(
				(
					groupedShops.find(group => group.country.code === selectedCountry.code)
						?.availableShops || []
				)
					.map(shop => getShopAvailableCurrencies(shop))
					.flat(),
				"code"
		  )
		: [];

	// Initialisation basée sur currentShop
	useEffect(() => {
		if (currentShop) {
			const hasUserSelection =
				values.country?.code || values.language?.code || values.currency?.code;

			if (!hasUserSelection) {
				const activeShop = groupedShops
					.flatMap(group => group.availableShops)
					.find(shop => shop.code === currentShop);

				if (activeShop) {
					const countryCode = activeShop.code.split("-")[1];

					const countryGroup = groupedShops.find(grp => grp.country.code === countryCode);
					const newCountryValue = {
						code: countryCode,
						label: countryGroup?.country.label || "",
					};

					const newLanguageValue = getShopLocalizedLanguage(activeShop);
					const newCurrencyValue = currentCurrency
						? {
								code: currentCurrency,
								symbol: getCurrencySymbol(activeShop?.code, currentCurrency),
						  }
						: getShopDefaultCurrency(activeShop);

					setFieldValue("country", newCountryValue);
					setFieldValue("language", newLanguageValue);
					setFieldValue("currency", newCurrencyValue);
				}
			}
		}
	}, [
		currentShop,
		currentCurrency,
		groupedShops,
		setFieldValue,
		values.country,
		values.language,
		values.currency,
	]);

	// Validation et Mise à Jour basée sur selectedCountry
	useEffect(() => {
		if (selectedCountry?.code) {
			const countryGroup = groupedShops.find(
				group => group.country.code === selectedCountry.code
			);

			if (!countryGroup) {
				// Si le pays sélectionné n'existe pas dans groupedShops
				if (selectedLanguage?.code) {
					setFieldValue("language", { code: "", label: "" });
				}
				if (selectedCurrency?.value) {
					setFieldValue("currency", { code: "", symbol: "" });
				}
			} else {
				const availableShops = countryGroup.availableShops;

				// Validation de la Langue
				const isLanguageValid = availableShops.some(
					shop => shop.language === selectedLanguage?.code
				);
				if (!isLanguageValid) {
					if (availableShops.length > 0) {
						const newLanguage = getShopLocalizedLanguage(availableShops[0]);
						setFieldValue("language", newLanguage);
					} else {
						setFieldValue("language", { code: "", label: "" });
					}
				}

				// Validation de la Devise
				const isCurrencyValid = availableShops.some(shop => {
					const currencies = getShopAvailableCurrencies(shop);

					// Vérifie si la devise sélectionnée est dans la liste des devises
					return currencies.some(currency => currency.code === selectedCurrency?.code);
				});

				if (!isCurrencyValid) {
					if (availableShops.length > 0) {
						const newCurrency = getShopDefaultCurrency(availableShops[0]);
						setFieldValue("currency", newCurrency);
					} else {
						setFieldValue("currency", { code: "", symbol: "" });
					}
				}
			}
		}
	}, [selectedCountry, selectedLanguage, selectedCurrency, groupedShops, setFieldValue]);

	const mobileProps = isMobile
		? {
				captureMenuScroll: false,
				menuShouldBlockScroll: hasKeyboard,
		  }
		: {};

	return (
		<div className="shop-switcher-detail-content">
			<AdvancedSelectFormik
				id="country"
				name="country"
				label={<FormattedMessage id="address.country.label" />}
				data-testid="shop-switcher-country-select-input"
				value={selectedCountry}
				isSearchable={false}
				options={countries}
				popperWidth={POPPER_WIDTH}
				menuListPadding={MENU_LIST_PADDING}
				menuMarginTop={MENU_MARGIN_TOP}
				menuPortalMargin={MENU_PORTAL_MARGIN}
				menuListMaxHeight={menuListMaxHeight}
				menuBorder={MENU_BORDER}
				menuBoxShadow={MENU_BOX_SHADOW}
				components={{
					SingleValue: CountryInputValueFormat,
				}}
				className="shop-switcher-detail-content__field"
				formatOptionLabel={option => (
					<div className="shop-switcher-detail-content__suggestion">
						<Typography
							variant={TYPOGRAPHY_VARIANTS.REGULAR}
							component="span"
							className="shop-switcher-detail-content__suggestion-label"
						>
							{option.label}
						</Typography>
						<i className={`icon icon--flag-${option.code}`} />
					</div>
				)}
				getOptionValue={({ code }) => code}
				getOptionLabel={({ label }) => label}
				{...mobileProps}
			/>

			<AdvancedSelectFormik
				id="language"
				name="language"
				label={<FormattedMessage id="general.shop.switcher.detail.language.label" />}
				data-testid="shop-switcher-language-select-input"
				value={selectedLanguage}
				isSearchable={false}
				popperWidth={POPPER_WIDTH}
				menuListPadding={MENU_LIST_PADDING}
				menuMarginTop={MENU_MARGIN_TOP}
				menuPortalMargin={MENU_PORTAL_MARGIN}
				menuListMaxHeight={menuListMaxHeight}
				menuBorder={MENU_BORDER}
				menuBoxShadow={MENU_BOX_SHADOW}
				className="shop-switcher-detail-content__field"
				options={availableLanguages}
				formatOptionLabel={option => (
					<div className="shop-switcher-detail-content__suggestion">
						<Typography
							variant={TYPOGRAPHY_VARIANTS.REGULAR}
							component="span"
							className="shop-switcher-detail-content__suggestion-label"
						>
							{option.label}
						</Typography>
					</div>
				)}
				getOptionValue={({ code }) => code}
				getOptionLabel={({ label }) => label}
				{...mobileProps}
			/>

			<AdvancedSelectFormik
				id="currency"
				name="currency"
				label={<FormattedMessage id="general.shop.switcher.detail.currency.label" />}
				data-testid="shop-switcher-currency-select-input"
				value={selectedCurrency}
				isSearchable={false}
				options={availableCurrencies}
				popperWidth={POPPER_WIDTH}
				menuListPadding={MENU_LIST_PADDING}
				menuMarginTop={MENU_MARGIN_TOP}
				menuListMaxHeight={menuListMaxHeight}
				menuPortalMargin={MENU_PORTAL_MARGIN}
				menuBorder={MENU_BORDER}
				menuBoxShadow={MENU_BOX_SHADOW}
				className="shop-switcher-detail-content__field"
				formatOptionLabel={option => (
					<div className="shop-switcher-detail-content__suggestion">
						{option.code && option.symbol && (
							<Typography
								variant={TYPOGRAPHY_VARIANTS.REGULAR}
								component="span"
								className="shop-switcher-detail-content__suggestion-label"
							>
								{option.code} - {option.symbol}
							</Typography>
						)}
					</div>
				)}
				getOptionValue={({ code }) => code}
				getOptionLabel={({ code }) => code}
				{...mobileProps}
			/>

			{(languageChanged || countryChanged) && (
				<AlertMessage
					message={
						<FormattedMessage id="general.shop.switcher.detail.redirect.warning.message" />
					}
				/>
			)}

			<Button
				variant="primary"
				submit
				loading={isSubmitting}
				data-testid="shop-switcher-form-button"
				disabled={isSubmitting}
			>
				<FormattedMessage id="promotion.code.submit.label" />
			</Button>
		</div>
	);
};

ShopSwitcherDetailContent.propTypes = {
	shops: PropTypes.arrayOf(
		PropTypes.shape({
			code: PropTypes.string,
			currencyCode: PropTypes.string,
			language: PropTypes.string,
		})
	),
	currentCurrency: PropTypes.string,
	currentShop: PropTypes.string,
};

export default memo(ShopSwitcherDetailContent);
