import classNames from "classnames";
import PropTypes from "prop-types";
import { memo, useContext } from "react";
import { FormattedMessage, FormattedPlural, FormattedNumber } from "react-intl";
import AppGlobalsContext from "app/AppGlobalsContext";
import { roundTripAdvisorRating } from "app/utils/utils";
import "./TripAdvisorRatingImage.scss";

const TripAdvisorRatingImage = ({ rating = 0, reviewsCount = 0, withoutLogo = true }) => {
	const roundedRating = roundTripAdvisorRating(rating);
	const ratingValue =
		roundedRating.toString().indexOf(".") === -1 ? roundedRating + ".0" : roundedRating;
	const { publicPath = "" } = useContext(AppGlobalsContext);

	return (
		<div
			className={classNames("tripadvisor-rating-image", {
				"tripadvisor-rating-image--without-logo": withoutLogo,
			})}
		>
			<img
				src={`${publicPath}/icons/tripadvisor/${ratingValue}-MCID-5${
					withoutLogo ? "-no-icon" : ""
				}.svg`}
				alt={`Trip advisor rating ${ratingValue}`}
			/>
			{reviewsCount > 0 && (
				<p
					className="tripadvisor-rating-image__reviews-count"
					data-testid="tripadvisor-rating-image-reviews-count"
				>
					{
						<FormattedPlural
							value={reviewsCount}
							one={
								<FormattedMessage
									id="product.review.count.singular"
									values={{
										reviewsCount: 1,
									}}
								/>
							}
							other={
								<FormattedMessage
									id="product.review.count.plural"
									values={{
										reviewsCount: (
											<FormattedNumber
												value={reviewsCount}
												styles="decimal"
											/>
										),
									}}
								/>
							}
						/>
					}
				</p>
			)}
		</div>
	);
};

TripAdvisorRatingImage.propTypes = {
	rating: PropTypes.number,
	reviewsCount: PropTypes.number,
	withoutLogo: PropTypes.bool,
};

export default memo(TripAdvisorRatingImage);
