import {
	CLEAR_SMARTDP,
	FETCH_SDP_AVAILABLE_DEPARTURE_DATE_SUCCESS,
	FETCH_SDP_FILTERS_SUCCESS,
	FETCH_SDP_QUOTE_FAILURE,
	FETCH_SDP_QUOTE_REQUEST,
	FETCH_SDP_QUOTE_SUCCESS,
	RESET_SDP_SEARCH_QUERY_STRING,
	SAVE_SDP_SEARCH_QUERY_STRING,
	SHOW_MORE_SDP_PRODUCTS,
	UPDATE_ACTIVE_SORT,
} from "app/pages/SmartDP/smartDPActionTypes";
import { SDP_PRODUCTS_PAGE_SIZE, SDP_ROTATION_DATE_FORMAT, SORT_TYPES } from "app/constants";
import { getDuration } from "app/utils/utils";
import {
	FETCH_ALL_STOPOVER_CITIES_SUCCESS,
	FETCH_STOPOVER_PACKAGE_REQUEST,
	FETCH_STOPOVER_PACKAGE_SUCCESS,
	FETCH_STOPOVER_QUOTE_FAILURE,
	FETCH_STOPOVER_QUOTE_REQUEST,
	FETCH_STOPOVER_QUOTE_SUCCESS,
} from "app/pages/Stopover/stopoverActionTypes";
import { FETCH_HOTEL_ONLY_FILTERS_SUCCESS } from "app/pages/HotelOnly/HotelOnlyActionTypes";
import { format } from "date-fns";

export const initialStaState = {
	status: "LOADING",
	accommodationsList: [],
	searchQueryString: "",
	accommodationsPageSize: SDP_PRODUCTS_PAGE_SIZE,
	destinations: [],
	departureCities: [],
	departureDateMin: undefined,
	departureDateMax: undefined,
	cabins: [],
	code: undefined,
	flights: [],
	specialOffer: undefined,
	specialOfferInfos: undefined,
	timestamp: undefined,
	sortedBy: SORT_TYPES.PRICE_ASC,
	recommendedSortActivated: undefined,
	sortUpdated: false, // used to prevent the frontend from sorting until user changes the sortedBy. Will be deprec when the flashsale products will be self-sorting (by the same criterias as the rest)
	boardTypeDescriptions: [],
	stopoverCities: [],
	stopoverDurations: [],
	availabilitiesDate: {},
};

export default (dpQuote = initialStaState, action) => {
	switch (action.type) {
		case FETCH_ALL_STOPOVER_CITIES_SUCCESS: {
			const data = action.res.data || {};

			return {
				...dpQuote,
				stopoverCities: data.availableCities?.map(city => {
					return {
						label: city.name,
						code: city.placeId,
					};
				}),
			};
		}
		case FETCH_STOPOVER_PACKAGE_REQUEST:
		case FETCH_STOPOVER_QUOTE_REQUEST:
		case FETCH_SDP_QUOTE_REQUEST: {
			return {
				...dpQuote,
				code: undefined,
				flights: [],
				status: "LOADING",
				specialOffer: undefined,
				specialOfferInfos: undefined,
				accommodationsList: [],
				quotationItems: [],
				accommodationsPageSize: SDP_PRODUCTS_PAGE_SIZE,
				timestamp: undefined,
				sortedBy: SORT_TYPES.PRICE_ASC,
				recommendedSortActivated: undefined,
				sortUpdated: false,
				boardTypeDescriptions: [],
			};
		}
		case FETCH_STOPOVER_QUOTE_FAILURE:
		case FETCH_SDP_QUOTE_FAILURE: {
			// permet notamment de detecte un bad request sur la sdp listing et afficher un message
			const status = action?.error?.response?.data?.status;

			return {
				...dpQuote,
				code: undefined,
				flights: [],
				status,
				specialOffer: undefined,
				specialOfferInfos: undefined,
				accommodationsList: [],
				quotationItems: [],
				accommodationsPageSize: SDP_PRODUCTS_PAGE_SIZE,
				timestamp: undefined,
				sortedBy: SORT_TYPES.PRICE_ASC,
				recommendedSortActivated: undefined,
				sortUpdated: false,
				boardTypeDescriptions: [],
			};
		}
		case SHOW_MORE_SDP_PRODUCTS:
			return {
				...dpQuote,
				accommodationsPageSize: dpQuote.accommodationsPageSize + SDP_PRODUCTS_PAGE_SIZE,
			};
		case SAVE_SDP_SEARCH_QUERY_STRING: {
			return {
				...dpQuote,
				searchQueryString: action.search,
			};
		}
		case RESET_SDP_SEARCH_QUERY_STRING: {
			return {
				...dpQuote,
				searchQueryString: "",
			};
		}
		case FETCH_STOPOVER_PACKAGE_SUCCESS:
		case FETCH_STOPOVER_QUOTE_SUCCESS: {
			const data = action.res.data || {};
			const now = new Date();

			return {
				...dpQuote,
				status: data.status,
				code: data.bookingFlowId,
				specialOffer: data.specialOffer,
				specialOfferInfos: data.specialOfferInfos,
				accommodationsList: data.packages?.accommodationsList?.map(accommodation => {
					if (accommodation.endAt) {
						accommodation.expiration = getDuration(now.getTime(), accommodation.endAt);
					}

					if (accommodation.startAt) {
						accommodation.availabilityDate = getDuration(
							now.getTime(),
							accommodation.startAt
						);
					}

					if (data.packages?.accommodationNights) {
						accommodation.accommodationNights = data.packages?.accommodationNights;
					}

					return accommodation;
				}),
				stopoverDurations: data.availableDurations,
				quotationItems: data.quotationItems || [],
				timestamp: new Date().getTime(),
				sortedBy: data.defaultSort,
				boardTypeDescriptions: data.packages?.boardTypeDescriptions || [],
				flights: data?.packages?.flights || [],
			};
		}
		case FETCH_SDP_QUOTE_SUCCESS: {
			const data = action.res.data || {};
			const now = new Date();

			return {
				...dpQuote,
				status: data.status,
				code: data.code,
				accommodationsList: data.accommodationsList?.map(accommodation => {
					if (accommodation.endAt) {
						accommodation.expiration = getDuration(now.getTime(), accommodation.endAt);
					}

					if (accommodation.startAt) {
						accommodation.availabilityDate = getDuration(
							now.getTime(),
							accommodation.startAt
						);
					}

					return accommodation;
				}),
				flights: data.flights || [],
				quotationItems: data.quotationItems || [],
				specialOffer: data.specialOffer,
				specialOfferInfos: data.specialOfferInfos,
				timestamp: new Date().getTime(),
				sortedBy: data.defaultSort,
				recommendedSortActivated: data.recommendedSortActivated,
				boardTypeDescriptions: data.boardTypeDescriptions || [],
			};
		}
		case FETCH_SDP_FILTERS_SUCCESS: {
			const filters = action.res.data;
			return {
				...dpQuote,
				departureCities: filters.departureCities,
				destinations: filters.destinations,
				cabins: filters.cabins,
				departureDateMin: filters.departureDateMin,
				departureDateMax: filters.departureDateMax,
			};
		}
		case FETCH_HOTEL_ONLY_FILTERS_SUCCESS: {
			const filters = action.res.data;
			return {
				...dpQuote,
				departureCities: [],
				destinations: filters.places,
				cabins: [],
				departureDateMin: filters.departureDateMin,
				departureDateMax: filters.departureDateMax,
			};
		}
		case CLEAR_SMARTDP: {
			return {
				...dpQuote,
				code: undefined,
				flights: [],
				status: "LOADING",
				specialOffer: undefined,
				specialOfferInfos: undefined,
				accommodationsList: [],
				quotationItems: [],
				accommodationsPageSize: SDP_PRODUCTS_PAGE_SIZE,
				timestamp: undefined,
				searchQueryString: "",
			};
		}
		case UPDATE_ACTIVE_SORT: {
			return {
				...dpQuote,
				sortedBy: action.activeSort,
				sortUpdated: true,
			};
		}
		case FETCH_SDP_AVAILABLE_DEPARTURE_DATE_SUCCESS: {
			const data = action?.res?.data || [];
			return {
				...dpQuote,
				availabilitiesDate: data?.reduce((acc, { prices, value }) => {
					// eslint-disable-next-line no-unused-expressions
					prices?.map(({ dd, ed }) => {
						const departureDate = format(new Date(dd), SDP_ROTATION_DATE_FORMAT);
						acc[departureDate] = acc[departureDate] || [];
						acc[departureDate].push({
							duration: value,
							ed: ed ? format(new Date(ed), SDP_ROTATION_DATE_FORMAT) : null,
						});
					});
					return acc;
				}, {}),
			};
		}
		default:
			return dpQuote;
	}
};
