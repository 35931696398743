import { createSelector } from "reselect";
import { getSelectedStopoverCityId } from "app/pages/Booking/bookingSelectors";
import { getDuration } from "app/utils/utils";
import { isEmpty } from "lodash";
import { format } from "date-fns";
import { SDP_ROTATION_DATE_FORMAT } from "app/constants";
import { getTravelLocationDataFromQuery } from "app/utils/smartDPUtils";

export const getOngoingSDPProducts = state => state.smartDP.accommodationsList;
export const getAvailableStopoverCities = state => state.smartDP.stopoverCities;
export const getSDPProductsPageSize = state => state.smartDP.accommodationsPageSize;
export const getSDPQuotationItems = state => state.smartDP.quotationItems;
export const getSDPFlights = state => state.smartDP.flights;
export const getActiveSort = state => state.smartDP.sortedBy;
export const getSortUpdated = state => state.smartDP.sortUpdated;
export const getRecommendedSortActivated = state => state.smartDP.recommendedSortActivated;
export const getAvailabilitiesDate = state => state?.smartDP?.availabilitiesDate;
export const getDepartureCities = state => state?.smartDP?.departureCities;
export const getDestinations = state => state?.smartDP?.destinations;

export const calculateSDPBasePrice = createSelector(
	[getSDPQuotationItems],
	(quotationItems = []) => {
		let total = 0;

		quotationItems.forEach(quotationItem => {
			total += quotationItem.price;
		});

		return total;
	}
);

export const getSelectedStopoverCity = createSelector(
	[getAvailableStopoverCities, getSelectedStopoverCityId],
	(availableStopoverCities = [], selectedStopoverCityId) => {
		return availableStopoverCities.find(city => city.code === selectedStopoverCityId);
	}
);

export const getSdpRotationUri = queryParams =>
	createSelector(
		[getDepartureCities, getDestinations],
		(departureCities = [], destinations = []) => {
			if (
				departureCities.length === 0 ||
				destinations.length === 0 ||
				!queryParams?.departureCityCode
			) {
				return undefined;
			}

			const { departure, destination } = getTravelLocationDataFromQuery({
				queryParams: {
					departureCityCode: queryParams?.departureCityCode,
					...(queryParams?.destinationResortCode && {
						destinationResortCode: queryParams?.destinationResortCode,
					}),
					...(queryParams?.destinationIATA && {
						destinationIATA: queryParams?.destinationIATA,
					}),
				},
				destinations: destinations,
				departures: departureCities,
			});
			if (isEmpty(destination) || isEmpty(departure)) {
				return undefined;
			}
			return `sdp${destination?.code.toString()}-${departure?.code.toString()}`;
		}
	);

export const getValidDuration = queryParams =>
	createSelector(
		[getAvailabilitiesDate],
		(availabilitiesDate = {}) => {
			if (queryParams?.duration) {
				return Number(queryParams?.duration);
			}
			if (
				isEmpty(availabilitiesDate) ||
				!(queryParams?.departureDate && queryParams?.endDate)
			) {
				return undefined;
			}

			const departureDate = new Date(Number(queryParams?.departureDate));
			const departureDateYMD = format(
				new Date(
					Date.UTC(
						departureDate.getUTCFullYear(),
						departureDate.getUTCMonth(),
						departureDate.getUTCDate()
					)
				),
				SDP_ROTATION_DATE_FORMAT
			);
			const endDate = new Date(Number(queryParams?.endDate));
			const endDateYMD = format(
				new Date(
					Date.UTC(endDate.getUTCFullYear(), endDate.getUTCMonth(), endDate.getUTCDate())
				),
				SDP_ROTATION_DATE_FORMAT
			);
			const dataOfSelectedDepartureDate = availabilitiesDate[departureDateYMD]?.find(
				data => data.ed === endDateYMD
			);

			return (
				dataOfSelectedDepartureDate.duration ||
				getDuration(queryParams?.departureDate, queryParams?.endDate)?.day
			);
		}
	);
