import PropTypes from "prop-types";
import SearchBookingForm from "app/pages/CorporateSite/SearchBooking/SearchBookingForm";
import { bindActionCreators } from "redux";
import { sendTagOnBookingSearch } from "app/utils/analytics";
import {
	authSuccess,
	authSuccessForShop,
	loginWithBookingReference,
} from "app/pages/Auth/AuthActions";
import { parseQueries } from "app/utils/routerUtils";
import { BRANDS, HTTP_STATUS_CODES } from "app/constants";
import { fetchMyBookingsNotification } from "app/pages/Account/MyBookings/myBookingsActionCreators";
import { useLocation, useNavigate } from "react-router-dom";
import { connect } from "react-redux";

const searchBookingErrorGenerate = status =>
	({
		[HTTP_STATUS_CODES.NOT_FOUND]: "error.booking.not.found.error",
		[HTTP_STATUS_CODES.TOO_MANY_REQUEST]: "error.booking.too.many.request.error",
	}[status] || "error.generic");

const SearchBookingFormContainer = ({
	onClickRecover = () => {},
	disabledRecoverData = false,
	authSuccess = () => {},
	fetchMyBookingsNotification = () => {},
	loginWithBookingReference = () => {},
	shop,
}) => {
	const location = useLocation();
	const navigate = useNavigate();

	const handleSubmit = (values, { setErrors, setStatus, setSubmitting }) => {
		sendTagOnBookingSearch({
			password: values.bookingReference,
			email: values.email,
		});

		loginWithBookingReference({
			password: values.bookingReference,
			email: values.email,
		}).then(res => {
			setSubmitting(false);

			if (res.status === HTTP_STATUS_CODES.OK) {
				setStatus({ success: true });
				authSuccess(res);

				const query = parseQueries(location.search);
				let pathname = "/account/mybookings";

				if (query.onauthsuccess) {
					pathname = query.onauthsuccess;
				}

				const currentShop = shop;
				const shopFormResponse = res?.data?.partner?.shop;
				const email = res?.data?.email;

				if (shopFormResponse && currentShop !== shopFormResponse) {
					const partnerCode = res?.data?.partner?.code;
					const token = res?.data?.token;
					const uuid = res?.data?.uuid;

					authSuccessForShop({ shop: shopFormResponse, partnerCode, token, uuid, email });

					fetchMyBookingsNotification({
						shop: shopFormResponse,
						brand: BRANDS.XX,
						email,
					});

					window.location = `${window.origin}/${shopFormResponse}${pathname}`;
				} else {
					authSuccess(res);
					fetchMyBookingsNotification({
						shop: currentShop,
						brand: BRANDS.XX,
						email,
					});

					if (query.onauthsuccess) {
						// we don't want the user to go to the auth page on back
						navigate(pathname, { replace: true });
					} else {
						navigate(pathname);
					}
				}
			} else {
				setErrors({
					_error: {
						id: searchBookingErrorGenerate(res.status),
					},
				});
				setStatus({ success: false });
			}
		});
	};

	return (
		<SearchBookingForm
			handleSubmit={handleSubmit}
			onClickRecover={onClickRecover}
			disabledRecoverData={disabledRecoverData}
		/>
	);
};

const mapStateToProps = state => ({
	shop: state.shop,
});

const mapDispatchToProps = dispatch => {
	return bindActionCreators(
		{
			authSuccess,
			loginWithBookingReference,
			fetchMyBookingsNotification,
		},
		dispatch
	);
};

SearchBookingFormContainer.propTypes = {
	onClickRecover: PropTypes.func,
	disabledRecoverData: PropTypes.bool,
	shop: PropTypes.string,
	authSuccess: PropTypes.func,
	fetchMyBookingsNotification: PropTypes.func,
	loginWithBookingReference: PropTypes.func,
};

export default connect(
	mapStateToProps,
	mapDispatchToProps
)(SearchBookingFormContainer);
