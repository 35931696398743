import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { getAllSwitchableShops } from "app/reducers/shopSelector";
import { getBrand } from "app/reducers/brandSelector";
import ShopSwitcherDetailModal from "app/pages/Home/ShopSwitcher/ShopSwitcherDetailModal";
import { updateCurrency } from "app/reducers/currencyActionCreators";

const mapStateToProps = state => {
	return {
		currentShop: state?.shop,
		shops: getAllSwitchableShops(state),
		brand: getBrand(state),
		currentCurrency: state?.currency,
	};
};

const mapDispatchToProps = dispatch => {
	return bindActionCreators(
		{
			updateCurrency,
		},
		dispatch
	);
};
export default connect(
	mapStateToProps,
	mapDispatchToProps
)(ShopSwitcherDetailModal);
