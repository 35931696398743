import axios from "axios";
import env from "app/utils/env";

export const FETCH_DISTRIBUTIONS = "FETCH_DISTRIBUTIONS";
export const FETCH_DISTRIBUTIONS_REQUEST = "FETCH_DISTRIBUTIONS_REQUEST";
export const FETCH_DISTRIBUTIONS_SUCCESS = "FETCH_DISTRIBUTIONS_SUCCESS";
export const FETCH_DISTRIBUTIONS_FAILURE = "FETCH_DISTRIBUTIONS_FAILURE";

export const HOTEL_ONLY_PREBOOK = "HOTEL_ONLY_PREBOOK";
export const HOTEL_ONLY_PREBOOK_REQUEST = "HOTEL_ONLY_PREBOOK_REQUEST";
export const HOTEL_ONLY_PREBOOK_SUCCESS = "HOTEL_ONLY_PREBOOK_SUCCESS";
export const HOTEL_ONLY_PREBOOK_FAILURE = "HOTEL_ONLY_PREBOOK_FAILURE";

export const fetchDistributions = payload => {
	const hotelOnlyUrl = env("HOTEL_ONLY_API_URL");
	return {
		type: FETCH_DISTRIBUTIONS,
		promise: axios.post(`${hotelOnlyUrl}/accommodation`, { ...payload }),
	};
};

export const hotelOnlyPrebook = payload => {
	const hotelOnlyUrl = env("HOTEL_ONLY_API_URL");
	return {
		type: HOTEL_ONLY_PREBOOK,
		promise: axios.post(`${hotelOnlyUrl}/preBook`, { ...payload }),
	};
};
