import { createSelector } from "reselect";
import { getPartnerMarketing } from "app/reducers/partnerSelector";
import flatten from "lodash/flatten";
import { getLocalizedCountryName } from "app/utils/utils";

const getBrandShops = state => state.brand.shops;
const getMarketing = state => getPartnerMarketing(state);
const getDestinationsList = state => state.smartDP.destinations;
const getShopSwitcherList = state => state?.brand?.shopSwitcherList;

export const getCurrentShop = state => state.shop;

export const getTopDestinations = createSelector(
	[getMarketing, getDestinationsList],
	(marketing = {}, destinationsList = []) => {
		const topDestinationsListCode = marketing?.topDestinations?.map(destination => ({
			// eslint-disable-next-line radix
			code: parseInt(destination?._ref?.split(".")[1]),
		}));

		return destinationsList
			.filter(item => topDestinationsListCode?.some(x => x.code === item.code))
			.sort(
				(item1, item2) =>
					topDestinationsListCode.findIndex(x => x.code === item1.code) -
					topDestinationsListCode.findIndex(x => x.code === item2.code)
			);
	}
);

export const getAllSwitchableShops = createSelector(
	[getBrandShops, getShopSwitcherList],
	(shops = [], shopSwitcherList = []) => {
		const refsSet = shopSwitcherList
			? new Set(shopSwitcherList.map(item => item.code))
			: new Set();

		const filteredShops = refsSet?.size > 0 ? shops.filter(item => refsSet.has(item._id)) : [];

		let switchableShops = flatten(
			filteredShops.map(shop => {
				const locales = shop.locales;

				return locales.map(locale => {
					return {
						shopCode: `${locale}-${shop.sellingCountry}`,
						currencyCode: shop.currencyCode,
						secondaryCurrencies: shop?.secondaryCurrencies,
					};
				});
			})
		);

		return switchableShops.map(({ shopCode, currencyCode, secondaryCurrencies }) => {
			return {
				code: shopCode,
				currencyCode: currencyCode,
				secondaryCurrencies: secondaryCurrencies,
				label: getLocalizedCountryName(shopCode),
			};
		});
	}
);
