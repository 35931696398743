import { connect } from "react-redux";
import PropTypes from "prop-types";
import { getAllSwitchableShops } from "app/reducers/shopSelector";

const ShopSwitcherContainer = props => {
	return typeof props.renderComponent === "function" && props.renderComponent(props);
};

ShopSwitcherContainer.propTypes = {
	shouldGetAllShops: PropTypes.bool,
};

const mapStateToProps = state => {
	return {
		currentShop: state?.shop,
		shops: getAllSwitchableShops(state),
		currencyCode: state.currency,
	};
};

export default connect(mapStateToProps)(ShopSwitcherContainer);
