import PropTypes from "prop-types";
import { memo } from "react";
import { components } from "react-select";
import Typography, { TYPOGRAPHY_VARIANTS } from "app/pages/.shared/Typography/Typography";

const CountryInputValueFormat = props => {
	const { data } = props;
	return (
		<components.SingleValue {...props}>
			<div className="shop-switcher-detail-content__suggestion shop-switcher-detail-content__suggestion-selected">
				<Typography
					variant={TYPOGRAPHY_VARIANTS.REGULAR}
					component="span"
					className="shop-switcher-detail-content__suggestion-label"
				>
					{data.label}
				</Typography>
				<i className={`icon icon--flag-${data.code}`} />
			</div>
		</components.SingleValue>
	);
};

CountryInputValueFormat.propTypes = {
	data: PropTypes.object,
};

export default memo(CountryInputValueFormat);
