import { useCallback, useContext } from "react";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { brandPropTypes, resolutionPropType } from "app/utils/propTypes";
import { BRANDS_WITH_ACCESS_TO_MY_BOOKING, FS_QUOTE_EVENT_NAME } from "app/constants";
import { useLocation } from "react-router-dom";
import { isAuthenticated } from "app/pages/Auth/authSelectors";
import { hasCustomDynamicHeader, isWhiteLabel } from "app/reducers/brandSelector";
import {
	getFallbackRedirectUrl,
	getFilteredMarketingBanners,
	getPartnerMarketing,
} from "app/reducers/partnerSelector.js";
import { getCallCenter } from "app/pages/Booking/bookingSelectors";
import {
	getAvailableCreditsTotal,
	getInscriptionCreditCampaign,
} from "app/pages/Account/MyCoupons/couponSelector";
import HeaderOfBrand from "@brand/Header/Header";
import {
	resetAllSDPProductsFilter,
	unsetSDPProductsFilter,
} from "app/pages/SmartDP/Listing/SmartDPFilters/smartDPProductsFilterActionCreators";
import { RESOLUTION } from "app/pages/.shared/responsive/responsiveReducer";
import { EventCategory, HitType, useFlagship } from "@flagship.io/react-sdk";
import MarketingBannerContainer from "app/pages/Header/MarketingBanner/MarketingBannerContainer";
import Header from "app/pages/Header/Header";
import appGlobalsContext from "app/AppGlobalsContext";

const HeaderContainer = props => {
	const {
		isBrandWithCustomHeader,
		isWhiteLabel,
		showMenuItems,
		resolution,
		marketingBanners,
		showMarketingBanner = true,
		isAsideMenuOpened = false,
		isTransparentHeader,
	} = props;

	const { brand } = useContext(appGlobalsContext);
	const { pathname } = useLocation();
	const { hit: fsHit } = useFlagship();

	const isMobile = resolution === RESOLUTION.MEDIUM || resolution === RESOLUTION.SMALL;

	const handleFlagShipEvent = useCallback(() => {
		fsHit.send({
			type: HitType.EVENT,
			category: EventCategory.ACTION_TRACKING,
			action: FS_QUOTE_EVENT_NAME.CLICK_ON_QUOTE_BACK,
		});
	}, []);

	const isBookingPage = pathname.includes("booking/");
	const isSdpPageListing = pathname.includes("sdp/listing");

	// on masque le header custom sur toutes les pages sdp sauf la home sdp (useHidePartnerCustomHeader dans SmartDP.jsx)
	// mais aussi dans les pages du tunnel (seHidePartnerCustomHeader dans BookingHeader)
	//  et on affiche le header commun sur la page sdp listing et les pages du tunnel
	const showMainHeader = isSdpPageListing || isBookingPage;

	// pour les brands avec header custom dynamique comme CD et HP
	const headerOfBrand = isBrandWithCustomHeader ? (
		false
	) : (
		<HeaderOfBrand
			{...props}
			onClickBackBtn={handleFlagShipEvent}
			isMobile={isMobile}
			showMenuItems={
				(isWhiteLabel || BRANDS_WITH_ACCESS_TO_MY_BOOKING.includes(brand)) && showMenuItems
			}
		/>
	);

	return (
		<>
			{showMarketingBanner && !isAsideMenuOpened && (
				<MarketingBannerContainer banners={marketingBanners} />
			)}
			{showMainHeader ? (
				<Header
					{...props}
					onClickBackBtn={handleFlagShipEvent}
					isMobile={isMobile}
					showMenuItems={
						(isWhiteLabel || BRANDS_WITH_ACCESS_TO_MY_BOOKING.includes(brand)) &&
						showMenuItems
					}
					isTransparentHeader={isTransparentHeader}
				/>
			) : (
				headerOfBrand
			)}
		</>
	);
};

HeaderContainer.propTypes = {
	brand: brandPropTypes,
	userIsConnected: PropTypes.bool,
	isWhiteLabel: PropTypes.bool,
	enableCredits: PropTypes.bool,
	isAsideMenuOpened: PropTypes.bool,
	previousUrl: PropTypes.string,
	fallbackRedirectUrl: PropTypes.string,
	marketingBanners: PropTypes.array,
	operatorEmail: PropTypes.string,
	dialPhone: PropTypes.string,
	merchandisingCode: PropTypes.string,
	creditsAmount: PropTypes.number,
	resolution: resolutionPropType,
	showMenuItems: PropTypes.bool,
	showDialPhone: PropTypes.bool,
	showBackBtn: PropTypes.bool,
	disabledHomeRedirect: PropTypes.bool,
	isBrandWithCustomHeader: PropTypes.bool,
	showMarketingBanner: PropTypes.bool,
	inscriptionCreditCampaign: PropTypes.object,
	isTransparentHeader: PropTypes.bool,
};

const mapStateToProps = state => {
	const marketing = getPartnerMarketing(state);
	const dialPhone =
		getCallCenter(state)?.contextPhones?.length > 0
			? getCallCenter(state)?.contextPhones[0]?.dialPhone
			: undefined;

	return {
		isWhiteLabel: isWhiteLabel(state),
		userIsConnected: isAuthenticated(state),
		operatorEmail: state.auth.operatorEmail,
		enableCredits: state.partner.enableCredits,
		brand: state.brand.code,
		fallbackRedirectUrl: getFallbackRedirectUrl(state),
		marketingBanners: getFilteredMarketingBanners(state),
		resolution: state.resolution,
		dialPhone: dialPhone,
		creditsAmount: getAvailableCreditsTotal(state),
		merchandisingCode: state?.merchandising?.current?.code,
		isBrandWithCustomHeader: hasCustomDynamicHeader(state),
		inscriptionCreditCampaign: getInscriptionCreditCampaign(state),
		isAsideMenuOpened: state.menu.isAsideMenuOpened,
		isTransparentHeader: marketing?.isTransparentSdpSearchHeader || false,
	};
};

const mapDispatchToProps = dispatch => {
	return bindActionCreators({ unsetSDPProductsFilter, resetAllSDPProductsFilter }, dispatch);
};

export default connect(
	mapStateToProps,
	mapDispatchToProps
)(HeaderContainer);
