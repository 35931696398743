import { createSelector } from "reselect";
import uniq from "lodash/uniq";
import uniqBy from "lodash/uniqBy";
import sortedUniq from "lodash/sortedUniq";
import compact from "lodash/compact";
import sortBy from "lodash/sortBy";
import take from "lodash/take";
import pull from "lodash/pull";
import flow from "lodash/flow";
import minBy from "lodash/minBy";
import pick from "lodash/pick";
import filter from "lodash/filter";
import flatten from "lodash/flatten";
import omitBy from "lodash/omitBy";
import isEqual from "lodash/isEqual";
import groupBy from "lodash/groupBy";
import max from "lodash/max";
import min from "lodash/min";
import forOwn from "lodash/forOwn";
import { getDurationAsMilliseconds } from "app/utils/utils";
import {
	CABINS,
	FLIGHTS_TAG,
	PRE_BOOK_CODE_STATUS,
	PRICE_CHANGE_STATUS,
	QUOTATION_CODE_STATUS,
	QUOTATION_MAX_DEFAULT_ACTIVITIES,
	QUOTATION_STATUS_VALIDE,
} from "app/constants";
import find from "lodash/find";
import get from "lodash/get";
import orderBy from "lodash/orderBy";

export const getQuotation = state => state.quotation;
const getAccommodations = state => state.quotation.accommodations;
const getBoardsDescription = state => state.quotation.boards;
const getRoomDescriptions = state => state.quotation.roomDescriptions;
const sortFlightsBy = state => state.quotation.sortFlightsBy;
const flightsPageSize = state => state.quotation.flightsPageSize;
const accommodationsPageSize = state => state.quotation.accommodationsPageSize;
const filterFlightsBy = state => state.quotation.filterFlightsBy;
const getBookingBoard = state => state.booking.board;
const getBookingAccommodation = state => state.booking.accommodation;
const getFlights = state => state.quotation.flights;
const getBooking = state => state.booking;
export const getQuoteStatus = state => state.quotation.status;
const getShowAllActivities = state => state.quotation.showAllActivities;
const getQuotationActivities = state => state.quotation.activities;
// false si special offer non elligible et si tous les vols sont non elligibles
export const getQuoteSpecialOffer = state => state.quotation.specialOffer;
export const getQuotationInsurances = state => state.quotation.insurances;

export const hasQuoteSpecialOffer = createSelector(
	[getQuoteStatus, getQuoteSpecialOffer],
	(quoteStatus, quoteSpecialOffer) => {
		return (
			quoteStatus === "LOADING" ||
			(QUOTATION_STATUS_VALIDE.includes(quoteStatus) && quoteSpecialOffer)
		);
	}
);

const limitFlights = (pageSize, flights) => {
	return take(flights, pageSize);
};

const sortFlights = (sortPredicate, flights) => {
	return sortBy(flights, sortPredicate);
};

const filterFlightsByTotalOutboundDuration = (duration, flights) => {
	const filteredFlights = filter(flights, flight => {
		const durationMilliseconds = getDurationAsMilliseconds(
			get(flight, "outbound.totalDuration")
		);
		return durationMilliseconds <= duration;
	});
	return filteredFlights;
};

const filterFlightsByTotalInboundDuration = (duration, flights) => {
	const filteredFlights = filter(flights, flight => {
		const durationMilliseconds = getDurationAsMilliseconds(
			get(flight, "inbound.totalDuration")
		);
		return durationMilliseconds <= duration;
	});
	return filteredFlights;
};

const filterFlightsByLuggages = (isIncluded, flights) => {
	const filteredFlights = filter(flights, flight => {
		return (
			flight.flightOptions &&
			flight.flightOptions.flightBagageOption &&
			flight.flightOptions.flightBagageOption.included === isIncluded
		);
	});
	return filteredFlights;
};

const filterFlightsByDepartureAirport = (airport, flights) => {
	const filteredFlights = filter(flights, flight => {
		return get(flight, "outbound.legs[0].from.airport") === airport;
	});
	return filteredFlights;
};

const filterFlightsByArrivalAirport = (airport, flights) => {
	const filteredFlights = filter(flights, flight => {
		const lastLegIndex = get(flight, "outbound.legs.length") - 1;
		return get(flight, `outbound.legs[${lastLegIndex}].to.airport`) === airport;
	});
	return filteredFlights;
};

const filterFlightsByUpgradePrice = (price, flights) => {
	const filteredFlights = filter(flights, flight => {
		return flight.upgradePrice <= price || flight.upgradePrice === undefined;
	});
	return filteredFlights;
};

const filterFlightsByCabin = (cabin, flights) => {
	const filteredFlights = filter(flights, flight => {
		return flight.outbound.cabin === cabin;
	});
	return filteredFlights;
};

const filterFlightsByAirline = (airline, flights) => {
	const filteredFlights = filter(flights, flight => {
		return flight.outbound.airline.name === airline;
	});
	return filteredFlights;
};

const filterFlightsByInboundTakeOffTime = (time, flights) => {
	const filteredFlights = filter(flights, flight => {
		const durationMilliseconds = getDurationAsMilliseconds(
			get(flight, "inbound.legs[0].from.takeOffTime")
		);
		return time.min <= durationMilliseconds && durationMilliseconds <= time.max;
	});
	return filteredFlights;
};

const filterFlightsByOutboundTakeOffTime = (time, flights) => {
	const filteredFlights = filter(flights, flight => {
		const durationMilliseconds = getDurationAsMilliseconds(
			get(flight, "outbound.legs[0].from.takeOffTime")
		);
		return time.min <= durationMilliseconds && durationMilliseconds <= time.max;
	});
	return filteredFlights;
};

const filterFlightsByStopCount = (stop, flights) => {
	const filteredFlights = filter(flights, flight => {
		// Si vol direct, alors on retourne les vols dont l'aller et le retour sont des vols directs
		if (stop === 0) {
			return (
				get(flight, "outbound.legs.length") - 1 === stop &&
				flight.inbound &&
				flight.inbound.legs.length - 1 === stop
			);
		}
		return get(flight, "outbound.legs.length") - 1 <= stop;
	});
	return filteredFlights;
};

export const getFilteredFlights = createSelector(
	[getFlights, filterFlightsBy],
	(flights = [], filterPredicates) => {
		const filters = [];

		if (filterPredicates.outboundDuration !== undefined) {
			filters.push(
				filterFlightsByTotalOutboundDuration.bind(this, filterPredicates.outboundDuration)
			);
		}

		if (filterPredicates.inboundDuration !== undefined) {
			filters.push(
				filterFlightsByTotalInboundDuration.bind(this, filterPredicates.inboundDuration)
			);
		}

		if (filterPredicates.luggage !== undefined) {
			filters.push(filterFlightsByLuggages.bind(this, filterPredicates.luggage));
		}

		if (filterPredicates.stop !== undefined) {
			filters.push(filterFlightsByStopCount.bind(this, filterPredicates.stop));
		}

		if (
			filterPredicates.inboundTakeOffTime &&
			filterPredicates.inboundTakeOffTime.min !== undefined &&
			filterPredicates.inboundTakeOffTime.max !== undefined
		) {
			filters.push(
				filterFlightsByInboundTakeOffTime.bind(this, filterPredicates.inboundTakeOffTime)
			);
		}

		if (
			filterPredicates.outboundTakeOffTime &&
			filterPredicates.outboundTakeOffTime.min !== undefined &&
			filterPredicates.outboundTakeOffTime.max !== undefined
		) {
			filters.push(
				filterFlightsByOutboundTakeOffTime.bind(this, filterPredicates.outboundTakeOffTime)
			);
		}

		if (filterPredicates.departureAirport !== undefined) {
			filters.push(
				filterFlightsByDepartureAirport.bind(this, filterPredicates.departureAirport)
			);
		}

		if (filterPredicates.arrivalAirport !== undefined) {
			filters.push(filterFlightsByArrivalAirport.bind(this, filterPredicates.arrivalAirport));
		}

		if (filterPredicates.airline !== undefined) {
			filters.push(filterFlightsByAirline.bind(this, filterPredicates.airline));
		}

		if (filterPredicates.upgradePrice !== undefined) {
			filters.push(filterFlightsByUpgradePrice.bind(this, filterPredicates.upgradePrice));
		}

		if (filterPredicates.cabin !== undefined) {
			filters.push(filterFlightsByCabin.bind(this, filterPredicates.cabin));
		}

		if (filters.length === 0) {
			return flights;
		}

		return flow(...filters)(flights);
	}
);

export const getDepartureAirportFilterValues = createSelector(
	[getFlights],
	(flights = []) => {
		const airports = flights.map(flight => {
			return get(pick(flight, "outbound"), "outbound.legs[0].from.airport");
		});
		return sortedUniq(airports.sort());
	}
);

export const getArrivalAirportFilterValues = createSelector(
	[getFlights],
	(flights = []) => {
		const airports = flights.map(flight => {
			const lastLegIndex = get(flight, "outbound.legs.length") - 1;
			return get(pick(flight, "outbound"), `outbound.legs[${lastLegIndex}].to.airport`);
		});
		return sortedUniq(airports.sort());
	}
);

export const getCabinFilterValues = createSelector(
	[getFlights],
	(flights = []) => {
		const cabins = flights.map(flight => {
			return pick(flight, "outbound").outbound.cabin;
		});
		return uniq(cabins);
	}
);

export const getLuggageFilterValues = createSelector(
	[getFlights],
	(flights = []) => {
		return [
			...new Set(flights.map(flight => flight?.flightOptions?.flightBagageOption?.included)),
		];
	}
);

export const getMinUpgradePricesGroupByCabin = createSelector(
	[getFlights],
	(flights = []) => {
		const upgradePriceByCabin = {};

		let flightsByCabin = groupBy(flights, flight => flight.outbound.cabin);

		forOwn(flightsByCabin, (flights, cabin) => {
			const upgradePrices = flights.map(flight => {
				return pick(flight, "upgradePrice").upgradePrice;
			});

			upgradePriceByCabin[cabin] = min(upgradePrices);
		});

		return upgradePriceByCabin;
	}
);

export const getMinUpgradePricesGroupByCabinPerPerson = createSelector(
	[getMinUpgradePricesGroupByCabin, getBooking],
	(upgradePriceByCabin = {}, booking = []) => {
		/* infant doesn't count */
		const passengersCount = booking.adults + booking.children;
		const upgradePriceByCabinPerPerson = { ...upgradePriceByCabin };

		for (const [key, value] of Object.entries(upgradePriceByCabin)) {
			upgradePriceByCabinPerPerson[key] = Math.ceil(value / passengersCount);
		}

		return upgradePriceByCabinPerPerson;
	}
);

export const getUpgradePriceFilterValues = createSelector(
	[getFlights],
	(flights = []) => {
		const upgradePrices = flights.map(flight => {
			return pick(flight, "upgradePrice").upgradePrice;
		});

		const maxUpgradePrice = max(upgradePrices);

		return maxUpgradePrice > 0 ? maxUpgradePrice : 0;
	}
);

export const getAirlinesFilterValues = createSelector(
	[getFlights],
	(flights = []) => {
		const airlines = flights.map(flight => {
			return pick(flight, "outbound").outbound.airline.name;
		});
		return sortedUniq(airlines.sort());
	}
);

export const getInboundDurationsFilterValues = createSelector(
	[getFlights],
	(flights = []) => {
		const durations = flights.map(flight => {
			return get(pick(flight, "inbound"), "inbound.totalDuration");
		});

		return {
			max: getDurationAsMilliseconds(max(durations)),
			min: getDurationAsMilliseconds(min(durations)),
		};
	}
);

export const getOutboundDurationsFilterValues = createSelector(
	[getFlights],
	(flights = []) => {
		const durations = flights.map(flight => {
			return get(pick(flight, "outbound"), "outbound.totalDuration");
		});

		return {
			max: getDurationAsMilliseconds(max(durations)),
			min: getDurationAsMilliseconds(min(durations)),
		};
	}
);

export const getInboundTakeOffTimeFilterValues = createSelector(
	[getFlights],
	(flights = []) => {
		const durations = flights.map(flight => {
			return get(pick(flight, "inbound"), "inbound.legs[0].from.takeOffTime");
		});

		return {
			max: getDurationAsMilliseconds(max(durations)),
			min: getDurationAsMilliseconds(min(durations)),
		};
	}
);

export const getOutboundTakeOffTimeFilterValues = createSelector(
	[getFlights],
	(flights = []) => {
		const durations = flights.map(flight => {
			return get(pick(flight, "outbound"), "outbound.legs[0].from.takeOffTime");
		});

		return {
			max: getDurationAsMilliseconds(max(durations)),
			min: getDurationAsMilliseconds(min(durations)),
		};
	}
);

export const getStopCountFilterValues = createSelector(
	[getFlights],
	(flights = []) => {
		const stops = flights.map(flight => {
			if (get(flight, "outbound.legs.length") - 1 === 0) {
				if (get(flight, "inbound.legs.length") - 1 === 0) {
					return 0;
				}
				return undefined;
			}
			return get(flight, "outbound.legs.length") - 1;
		});

		return uniq(pull(stops, undefined));
	}
);

export const getFiltersCount = createSelector(
	[
		filterFlightsBy,
		getInboundDurationsFilterValues,
		getOutboundDurationsFilterValues,
		getInboundTakeOffTimeFilterValues,
		getOutboundTakeOffTimeFilterValues,
		getUpgradePriceFilterValues,
	],
	(
		filters = {},
		inboundDurationFilterValues = {},
		outboundDurationFilterValues = {},
		inboundTakeOffTimeFilterValues = {},
		outboundTakeOffTimeFilterValues = {},
		upgradePriceFilterValues = {}
	) => {
		return Object.keys(
			omitBy(filters, (filterValue, filterKey) => {
				return (
					filterValue === undefined ||
					filterKey === "cabin" ||
					isEqual(filterValue, inboundDurationFilterValues.max) ||
					isEqual(filterValue, outboundDurationFilterValues.max) ||
					isEqual(filterValue, inboundTakeOffTimeFilterValues) ||
					isEqual(filterValue, outboundTakeOffTimeFilterValues) ||
					filterValue === upgradePriceFilterValues
				);
			})
		).length;
	}
);

export const getFormattedAccommodations = createSelector(
	[getAccommodations, getRoomDescriptions],
	(accommodations = [], roomDescriptions = []) =>
		accommodations.map(accommodation => {
			const accommodationItems = accommodation.items
				? accommodation.items.map(item => {
						// eslint-disable-next-line max-nested-callbacks
						const roomDescription = find(roomDescriptions, roomDescription => {
							return (
								item.accommodationItemCode === roomDescription.accommodationItemCode
							);
						});
						return Object.assign({}, item, roomDescription);
				  })
				: [];

			const roomsCode = accommodationItems.map(item => item.accommodationItemCode);

			const uniquRoomCodes = uniq(roomsCode);

			const roomDetails = uniquRoomCodes.map(accommodationItemCode => {
				// eslint-disable-next-line max-nested-callbacks
				return find(roomDescriptions, roomDescription => {
					return roomDescription.accommodationItemCode === accommodationItemCode; // eslint-disable-line max-nested-callbacks
				});
			});

			return {
				...accommodation,
				code: "room-" + accommodation.code,
				details: roomDetails ? roomDetails : [],
				items: accommodationItems,
			};
		})
);

export const limitAccommodations = (pageSize, accommodations) => take(accommodations, pageSize);

export const getVisibleAccommodations = createSelector(
	[getFormattedAccommodations, accommodationsPageSize],
	(accommodations = [], accommodationsPageSize) => {
		return limitAccommodations(accommodationsPageSize + 1, accommodations);
	}
);

export const getIncludedAccommodation = createSelector(
	[getFormattedAccommodations],
	(accommodations = []) => {
		return find(accommodations, accommodation => accommodation.included === true);
	}
);

export const getQuotationBoardDescription = createSelector(
	[getQuotation],
	(quotation = {}) => {
		return quotation.boards;
	}
);

export const getAccommodationsWithFormattedBoards = createSelector(
	[getFormattedAccommodations, getQuotationBoardDescription],
	(accommodations = [], boardDescriptions = []) => {
		return flatten(
			accommodations.map(accommodation => {
				// TODO delete .codes which is replaced by .boards
				const boardsOfAccommodation = accommodation.boards || accommodation.codes;

				return boardsOfAccommodation.map(board => {
					// eslint-disable-next-line max-nested-callbacks
					const boardDescription = boardDescriptions.find(boardDescription => {
						return board.boardCode === boardDescription.boardCode;
					});

					const totalUpgradePrice =
						(accommodation.upgradePrice || 0) + (board.upgradePrice || 0);

					const formattedBoard = {
						...accommodation,
						upgradePrice: totalUpgradePrice,
						// included: board.included || accommodation.included,
						board: {
							boardCode: board.boardCode,
							code: board.code,
							included: board.included,
							...boardDescription,
						},
					};

					// TODO delete .codes which is replaced by .boards
					delete formattedBoard.boards;
					delete formattedBoard.codes;

					return formattedBoard;
				});
			})
		);
	}
);

export const getVisibleBoards = createSelector(
	[getAccommodationsWithFormattedBoards, getBoardsDescription],
	(accommodations = [], boardDescriptions = []) => {
		const partitionedBoardsDescription = groupBy(
			accommodations,
			(accommodation = {}) => accommodation.board?.boardCode
		);

		const visibleBoards = boardDescriptions.map(description => {
			const accommodationsByBoard = partitionedBoardsDescription[description.boardCode];

			if (accommodationsByBoard) {
				const cheapeastBoard = minBy(accommodationsByBoard, o => o.upgradePrice);
				const boardToShow = {
					...cheapeastBoard,
					...cheapeastBoard.board,
					code: "board-" + cheapeastBoard.board?.code,
				};

				delete boardToShow.board;
				return boardToShow;
			}

			return undefined;
		});

		const orderedVisibleBoards = orderBy(compact(visibleBoards), board => board.upgradePrice, [
			"asc",
		]);

		// the cheapest board is included
		if (orderedVisibleBoards && orderedVisibleBoards.length > 0) {
			orderedVisibleBoards[0].included = true;
		}

		return orderedVisibleBoards;
	}
);

// the board selected by default is the board included in the included room
export const getIncludedBoard = createSelector(
	[getIncludedAccommodation],
	(includedAccommodation = {}) => {
		// TODO delete .codes which is replaced by .boards
		const boards = includedAccommodation.boards || includedAccommodation.codes;
		return boards?.find(board => board.included === true);
	}
);

export const getSelectedBoard = createSelector(
	[getIncludedBoard, getBookingBoard],
	(includedBoard = {}, userSelectedBoard = {}) => {
		if (userSelectedBoard.code) {
			return userSelectedBoard;
		} else if (includedBoard) {
			return includedBoard;
		}
		return {};
	}
);

export const getSelectedAccommodation = createSelector(
	[getIncludedAccommodation, getBookingAccommodation],
	(includedAccommodation = {}, userSelectedAccommodation = {}) => {
		if (userSelectedAccommodation.code) {
			return userSelectedAccommodation;
		} else if (includedAccommodation) {
			return includedAccommodation;
		}
		return {};
	}
);

export const getBoardsOfSelectedAccommodation = createSelector(
	[getSelectedAccommodation, getBoardsDescription],
	(selectedAccommodation = {}, boardsDescription = []) => {
		// TODO delete .codes which is replaced by .boards
		const boards = selectedAccommodation.boards || selectedAccommodation.codes;

		const uniqCodesOfSelectedAccommodations = boards
			? uniqBy(boards, board => board.boardCode)
			: [];

		/* Inserting { ..., included, upgradePrice } to descriptions */
		const selectedBoardsDescription = uniqCodesOfSelectedAccommodations.map(
			boardOfSelectedAccommodation => {
				const matchingBoard = find(boardsDescription, boardDescription => {
					return boardDescription.boardCode === boardOfSelectedAccommodation.boardCode;
				});

				const { included, upgradePrice, code } = boardOfSelectedAccommodation;
				return {
					...matchingBoard,
					included,
					upgradePrice: upgradePrice ? upgradePrice : 0,
					code,
				};
			}
		);

		return sortBy(selectedBoardsDescription, board => board.upgradePrice);
	}
);

export const getAccommodationsOfSelectedBoard = createSelector(
	[getSelectedBoard, getAccommodationsWithFormattedBoards, getVisibleBoards],
	(selectedBoard = {}, accommodations = [], visibleBoards = []) => {
		const selectedBoardcode = selectedBoard.boardCode;

		const accommodationOfSelectedBoard = accommodations.filter(accommodation => {
			return accommodation.board?.boardCode === selectedBoardcode;
		});

		const cheapestBoard = visibleBoards.find(board => {
			return board.boardCode === selectedBoardcode;
		});

		const formattedAccommodationOfSelectedBoard = accommodationOfSelectedBoard.map(
			(accommodation = {}) => {
				const acc = {
					...accommodation,
					// le code est utilisé comme id pour la key react
					// on prend le code de board au lieu de l'accommodation
					// car on ne doit pas envoyer le code de l'accommodation dans le payload du prebook
					code: accommodation.board?.code,
					upgradePrice: accommodation.upgradePrice - cheapestBoard.upgradePrice,
				};
				return acc;
			}
		);

		return orderBy(formattedAccommodationOfSelectedBoard, a => a.upgradePrice, ["asc"]);
	}
);

export const getVisibleAccommodationsOfSelectedBoard = createSelector(
	[getAccommodationsOfSelectedBoard, accommodationsPageSize],
	(accommodations = [], accommodationsPageSize) => {
		return limitAccommodations(accommodationsPageSize + 1, accommodations);
	}
);

export const getIncludedFlight = createSelector(
	[getFlights],
	(flights = []) => {
		return find(flights, flight => flight.included === true);
	}
);

export const getTransfersOfSelectedFlight = createSelector(
	[getQuotation],
	(quotation = {}) => {
		return quotation.transfers || [];
	}
);

export const getIncludedTransfer = createSelector(
	[getTransfersOfSelectedFlight],
	(transfers = []) => {
		return find(transfers, transfer => transfer.included === true);
	}
);

export const checkShowFlightFilters = createSelector(
	[getFlights],
	(flights = []) => {
		const flighsPerCabin = groupBy(flights, flight => {
			return flight.outbound.cabin;
		});

		let shouldHideFlightFilters = false;

		Object.keys(flighsPerCabin).forEach(flight => {
			if (flighsPerCabin[flight].length > 5) {
				shouldHideFlightFilters = true;
			}
		});

		return shouldHideFlightFilters;
	}
);

export const getTaggedFlights = createSelector(
	[getFlights],
	(flights = []) => {
		const taggedFlights = flights.filter(flight => flight.tag);
		return taggedFlights;
	}
);

export const getVisibleFlights = createSelector(
	[
		getFilteredFlights,
		getTaggedFlights,
		flightsPageSize,
		sortFlightsBy,
		getFiltersCount,
		filterFlightsBy,
	],
	(
		flights = [],
		taggedFlights = [],
		flightsPageSize,
		sortFlightsBy,
		filtersCount,
		filterFlightsBy = {}
	) => {
		return filtersCount === 0 && filterFlightsBy.cabin === CABINS.ECONOMY
			? limitFlights(
					flightsPageSize,
					uniqBy([...taggedFlights, ...sortFlights(sortFlightsBy, flights)], "code")
			  )
			: flow(
					sortFlights.bind(this, sortFlightsBy),
					limitFlights.bind(this, flightsPageSize)
			  )(flights);
	}
);

export const getActivities = createSelector(
	[getQuotationActivities, getShowAllActivities],
	(activities, showAllActivities) => {
		if (showAllActivities) {
			return activities;
		}

		return activities.slice(0, QUOTATION_MAX_DEFAULT_ACTIVITIES);
	}
);

export const shouldNotShowAllActivities = createSelector(
	[getQuotationActivities, getShowAllActivities],
	(activities, showAllActivities) => {
		return !showAllActivities && activities.length > QUOTATION_MAX_DEFAULT_ACTIVITIES;
	}
);

export const getCheapestFlight = createSelector(
	[getFlights],
	(flights = []) => {
		return flights.find(flight => flight.tag === FLIGHTS_TAG.CHEAPEST);
	}
);

export const getFlightOptionsStatus = createSelector(
	[getQuotation],
	(quotation = {}) => {
		return quotation.flightOptionsStatus;
	}
);

export const getQuotationPriceChangeData = createSelector(
	[getQuotation],
	(quotation = {}) => {
		let priceChangeStatus;
		const isPriceChangedUp =
			quotation?.priceChange?.newPrice - quotation?.priceChange?.oldPrice > 0;
		const isPriceChangedDown =
			quotation?.priceChange?.newPrice - quotation?.priceChange?.oldPrice < 0;
		const isStatusWarning =
			quotation.status === QUOTATION_CODE_STATUS.WARNING ||
			quotation.preBookStatus === PRE_BOOK_CODE_STATUS.WARNING;

		const isPriceChange = (isPriceChangedUp || isPriceChangedDown) && isStatusWarning;
		if (isPriceChangedUp) {
			priceChangeStatus = PRICE_CHANGE_STATUS.UP;
		}
		if (isPriceChangedDown) {
			priceChangeStatus = PRICE_CHANGE_STATUS.DOWN;
		}
		return {
			isPriceChange,
			...(isPriceChange && {
				priceChangeStatus,
			}),
			newPrice: quotation?.priceChange?.newPrice,
			oldPrice: quotation?.priceChange?.oldPrice,
		};
	}
);

export const getPrebookStatus = createSelector(
	[getQuotation],
	(quotation = {}) => {
		return quotation.preBookStatus;
	}
);

export const getDistributions = createSelector(
	[getQuotation],
	(quotation = {}) => {
		return quotation.distributions || [];
	}
);
