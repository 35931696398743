import {
	CHECK_AVAILABILITIES_REQUEST,
	CHECK_AVAILABILITIES_SUCCESS,
	CLEAR_QUOTATION,
	FETCH_FLIGHT_OPTIONS_FAILURE,
	FETCH_FLIGHT_OPTIONS_REQUEST,
	FETCH_FLIGHT_OPTIONS_SUCCESS,
	FETCH_PRODUCTS_REQUEST,
	INIT_ACCOMMODATIONS_PAGE_SIZE,
	PRE_BOOK_FAILURE,
	PRE_BOOK_REQUEST,
	PRE_BOOK_SUCCESS,
	RESET_FLIGHTS_FILTER,
	RESET_FLIGHTS_PAGE_SIZE,
	RESTORE_BOOKING_SESSION_REQUEST,
	RESTORE_BOOKING_SESSION_SUCCESS,
	SAVE_QUOTATION_SEARCH_QUERY_STRING,
	SET_FLIGHTS_FILTER_BY,
	SET_FLIGHTS_SORT_BY,
	SHOW_FLEX_RATES_MODAL,
	SHOW_MORE_FLIGHTS,
} from "app/actionTypes";
import get from "lodash/get";
import cloneDeep from "lodash/cloneDeep";
import { actionTypes as reduxActionTypes } from "redux-form";
import {
	ACCOMMODATIONS_PAGE_SIZE,
	FLIGHT_OPTIONS_STATUS,
	FLIGHTS_PAGE_SIZE,
	PRE_BOOK_CODE_STATUS,
	QUOTATION_CODE_STATUS,
	QUOTATION_STATUS_VALIDE,
	REFUNDABILITY_TYPES,
} from "app/constants";
import {
	SHOW_ALL_ACTIVITIES,
	SHOW_MORE_ACCOMMODATIONS,
} from "app/pages/Booking/Quotation/quotationActionTypes";
import {
	CHECK_SDP_AVAILABILITIES_REQUEST,
	CHECK_SDP_AVAILABILITIES_SUCCESS,
} from "app/pages/SmartDP/smartDPActionTypes";
import {
	FETCH_STOPOVER_PACKAGE_REQUEST,
	FETCH_STOPOVER_PACKAGE_SUCCESS,
	FETCH_STOPOVER_QUOTE_REQUEST,
	FETCH_STOPOVER_QUOTE_SUCCESS,
	CHECK_STOPOVER_AVAILABILITIES_REQUEST,
	CHECK_STOPOVER_AVAILABILITIES_SUCCESS,
} from "app/pages/Stopover/stopoverActionTypes";
import {
	FETCH_DISTRIBUTIONS_REQUEST,
	FETCH_DISTRIBUTIONS_SUCCESS,
	HOTEL_ONLY_PREBOOK_FAILURE,
	HOTEL_ONLY_PREBOOK_REQUEST,
	HOTEL_ONLY_PREBOOK_SUCCESS,
} from "app/pages/HotelOnly/Booking/pages/Quote/BookingQuoteActionCreator";
import { calculateAgeFromBirthday } from "app/utils/utils";

export const initialQuotation = {
	timestamp: undefined,
	status: QUOTATION_CODE_STATUS.LOADING,
	searchQueryString: "",
	preBookStatus: undefined,
	flightOptionsStatus: undefined, // should not be loading state to not disabled quote cta button if offer type is AO
	flightOptionsFlightCode: undefined,
	flightOptions: {},
	transfers: [],
	code: undefined,
	accommodations: [],
	roomDescriptions: [],
	boards: [],
	activities: [],
	flights: [],
	insurances: [],
	isSmartDP: undefined,
	quotationItems: [],
	sortFlightsBy: "price",
	filterFlightsBy: {
		stop: undefined,
		inboundDuration: undefined,
		outboundDuration: undefined,
		inboundTakeOffTime: {
			min: undefined,
			max: undefined,
		},
		outboundTakeOffTime: {
			min: undefined,
			max: undefined,
		},
		departureAirport: undefined,
		arrivalAirport: undefined,
		airline: undefined,
		luggage: undefined,
		upgradePrice: undefined,
		cabin: undefined,
	},
	flightsPageSize: FLIGHTS_PAGE_SIZE,
	accommodationsPageSize: ACCOMMODATIONS_PAGE_SIZE,
	showAllActivities: false,
	deltaBasePrice: 0,
	priceChange: {},
	taggedFlightsNumber: FLIGHTS_PAGE_SIZE,
	specialOffer: undefined,
	isFlexRatesModalOpened: false,
	distributions: [],
};

export const formatDistributions = data => {
	const distributions = data?.accommodation?.distributions || [];
	return distributions.map((distribution, index) => {
		const birthDates = distribution.occupancy?.childrenBirthDates || [];
		const children = birthDates.filter(birthDate => calculateAgeFromBirthday(birthDate) >= 2)
			.length;
		const babies = birthDates.filter(birthDate => calculateAgeFromBirthday(birthDate) < 2)
			.length;

		return {
			id: `distribution-${index + 1}`,
			occupancy: {
				adults: distribution.occupancy.adults,
				children,
				babies,
			},
			rooms:
				distribution?.rooms?.map(room => ({
					id: room?.id,
					type: room?.name,
					amenities: room?.amenities, // Waiting for Tourop to provide this information
					boards: room?.rates?.map(rate => ({
						id: rate?.id,
						type: rate?.board?.label,
						initialPricePerNight: rate?.price?.perNight?.net,
						finalPricePerNight: rate?.price?.perNight?.discounted,
						initialPricePerStay: rate?.price?.perStay?.net,
						finalPricePerStay: rate?.price?.perStay?.discountedWithAllTaxes,
						finalPricePerStayWithoutTaxes: rate?.price?.perStay?.discounted,
						discountPercentagePerNight:
							rate?.price?.perNight?.discount?.[0]?.percentage,
						discountPercentagePerStay: rate?.price?.perStay?.discount?.[0]?.percentage,
						freeCancellationDeadline:
							rate?.refundable === REFUNDABILITY_TYPES.FULL
								? rate?.cancellationPolicies?.[0]?.limitDate
								: undefined,
						totalExcludedTaxes:
							rate?.price?.perStay?.taxes
								?.filter(tax => !tax?.included)
								?.reduce((acc, tax) => acc + tax?.amount, 0) || 0,
						totalIncludedTaxes:
							rate?.price?.perStay?.taxes
								?.filter(tax => tax?.included)
								?.reduce((acc, tax) => acc + tax?.amount, 0) || 0,
						details: null, // Waiting for Tourop to provide this information
					})),
				})) || [],
		};
	});
};

export default (quotation = initialQuotation, action) => {
	switch (action.type) {
		case INIT_ACCOMMODATIONS_PAGE_SIZE: {
			return {
				...quotation,
				accommodationsPageSize: action.accommodationsPageSize,
			};
		}

		case reduxActionTypes.CHANGE: {
			// Si FULL_FLIGHT apres un pre book, on affiche un message erreur au dessus du bloc de vol
			// On supprime le message d'erreur si la personne sélectionne un autre vol

			const nextState = { ...quotation };

			if (
				action.form === "booking-quotation-form" &&
				action.field === "flight" &&
				quotation.preBookStatus === PRE_BOOK_CODE_STATUS.FULL_FLIGHT
			) {
				nextState.preBookStatus = undefined;
			}

			return nextState;
		}
		case SAVE_QUOTATION_SEARCH_QUERY_STRING: {
			return {
				...quotation,
				searchQueryString: action.search,
			};
		}
		case RESTORE_BOOKING_SESSION_REQUEST:
		case FETCH_PRODUCTS_REQUEST:
		case CHECK_SDP_AVAILABILITIES_REQUEST:
		case CHECK_AVAILABILITIES_REQUEST:
		case FETCH_STOPOVER_PACKAGE_REQUEST:
		case FETCH_STOPOVER_QUOTE_REQUEST:
			return {
				...initialQuotation,
			};
		case CLEAR_QUOTATION: {
			const { keepFlights } = action;
			return {
				...initialQuotation,
				flights: keepFlights ? quotation.flights : [], // do not reset flights that are fetched from the previous quote in stopover listing
			};
		}
		case CHECK_STOPOVER_AVAILABILITIES_REQUEST:
			return {
				...initialQuotation,
				flights: quotation.flights, // do not reset flights that are fetched from the previous quote in stopover listing
				taggedFlightsNumber: quotation.taggedFlightsNumber,
				flightsPageSize: quotation.flightsPageSize,
			};
		case FETCH_STOPOVER_PACKAGE_SUCCESS:
		case FETCH_STOPOVER_QUOTE_SUCCESS: {
			const data = action.res.data;
			if (QUOTATION_STATUS_VALIDE.includes(data.status)) {
				return {
					...quotation,
					timestamp: Date.now(),
					status: data.status,
					code: data.bookingFlowId || data.code, // TODO remove || data.code when all stopover quote will have bookingFlowId instead of quote
					flights: data.packages?.flights || [],
				};
			}
			return {
				...quotation,
				status: data.status,
				code: data.bookingFlowId || data.code, // TODO remove || data.code when all stopover quote will have bookingFlowId instead of quote
				flights: [],
				quotationItems: [],
				specialOffer: undefined,
			};
		}
		case CHECK_SDP_AVAILABILITIES_SUCCESS:
		case CHECK_AVAILABILITIES_SUCCESS: {
			const data = action.res.data;
			if (QUOTATION_STATUS_VALIDE.includes(data.status)) {
				const taggedFlights =
					(data.flights && data.flights.filter(flight => flight.tag)) || [];

				return {
					...quotation,
					timestamp: Date.now(),
					status: data.status,
					code: data.code,
					activities: data.activities || [],
					insurances: data.insurances || [],
					accommodations: get(data, "accommodations.accommodationItems", []), // can be undefined for smartdp
					roomDescriptions: get(data, "accommodations.accommodationItemDescriptions", []), // can be undefined for smartdp
					boards: get(data, "accommodations.boards", []), // can be undefined for smartdp
					flights: data.flights || [],
					quotationItems: data.quotationItems,
					showBoardsFirst: data.showBoardsFirst,
					accommodationsPageSize: ACCOMMODATIONS_PAGE_SIZE,
					taggedFlightsNumber: taggedFlights.length,
					flightsPageSize: taggedFlights.length,
					isSmartDP: data.isSmartDP,
					specialOffer: data.specialOffer || undefined,
					...(data.priceChange && {
						priceChange: data.priceChange,
						deltaBasePrice: data.priceChange?.newPrice - data.priceChange?.oldPrice,
					}),
				};
			}
			return {
				...quotation,
				status: data.status,
				code: data.code,
				activities: [],
				insurances: [],
				accommodations: [],
				roomDescriptions: [],
				boards: [],
				flights: [],
				quotationItems: [],
				specialOffer: undefined,
			};
		}
		case CHECK_STOPOVER_AVAILABILITIES_SUCCESS: {
			const data = action.res.data;
			if (QUOTATION_STATUS_VALIDE.includes(data.status)) {
				return {
					...quotation,
					timestamp: Date.now(),
					status: data.status,
					code: data.bookingFlowId || data.code, // TODO remove || data.code when all stopover quote will have bookingFlowId instead of quote
					activities: data.activities || [],
					insurances: data.insurances || [],
					accommodations: get(data, "accommodations.accommodationItems", []), // can be undefined for smartdp
					roomDescriptions: get(data, "accommodations.accommodationItemDescriptions", []), // can be undefined for smartdp
					boards: get(data, "accommodations.boards", []), // can be undefined for smartdp
					flights: quotation.flights || [], // do not reset flights that are fetched from the previous quote in stopover listing
					quotationItems: data.quotationItems,
					showBoardsFirst: data.showBoardsFirst,
					accommodationsPageSize: ACCOMMODATIONS_PAGE_SIZE,
					taggedFlightsNumber: quotation.taggedFlightsNumber,
					flightsPageSize: quotation.flightsPageSize,
					isSmartDP: data.isSmartDP,
					specialOffer: data.specialOffer || undefined,
					...(data.priceChange && {
						priceChange: data.priceChange,
						deltaBasePrice: data.priceChange?.newPrice - data.priceChange?.oldPrice,
					}),
				};
			}
			return {
				...quotation,
				status: data.status,
				code: data.code,
				activities: [],
				insurances: [],
				accommodations: [],
				roomDescriptions: [],
				boards: [],
				flights: [],
				quotationItems: [],
				specialOffer: undefined,
			};
		}
		case RESTORE_BOOKING_SESSION_SUCCESS: {
			const {
				quoteResponse = {},
				preBookResponse,
				flightOptionsResponse = {},
			} = action.res.data;
			const preBookStatus = preBookResponse.status;
			const taggedFlights =
				(quoteResponse.flights && quoteResponse.flights.filter(flight => flight.tag)) || [];

			let newState = {
				...quotation,
				status: quoteResponse.status,
				preBookStatus,
				timestamp: Date.now(),
				code: quoteResponse.code,
				activities: quoteResponse.activities || [],
				insurances: quoteResponse.insurances || [],
				accommodations: quoteResponse?.accommodations?.accommodationItems || [], // can be undefined for smartdp
				roomDescriptions:
					quoteResponse?.accommodations?.accommodationItemDescriptions || [], // can be undefined for smartdp
				boards: quoteResponse.accommodations.boards || [], // can be undefined for smartdp
				flights: quoteResponse.flights,
				transfers: flightOptionsResponse.transfers || [],
				flightOptions: {
					...quotation.flightOptions,
					...(flightOptionsResponse.flightOptions && {
						[flightOptionsResponse.flight]: flightOptionsResponse.flightOptions,
					}),
				},
				quotationItems: quoteResponse.quotationItems,
				showBoardsFirst: quoteResponse.showBoardsFirst,
				accommodationsPageSize: ACCOMMODATIONS_PAGE_SIZE,
				taggedFlightsNumber: taggedFlights.length,
				flightsPageSize: taggedFlights.length,
				specialOffer: quoteResponse.specialOffer,
			};

			if (quoteResponse.status === PRE_BOOK_CODE_STATUS.WARNING) {
				newState.priceChange = quoteResponse.priceChange;
				newState.deltaBasePrice =
					quoteResponse.priceChange.newPrice - quoteResponse.priceChange.oldPrice;
			}

			return newState;
		}
		case SET_FLIGHTS_SORT_BY:
			return { ...quotation, sortFlightsBy: action.sortBy };
		case SET_FLIGHTS_FILTER_BY:
			return {
				...quotation,
				filterFlightsBy: { ...quotation.filterFlightsBy, ...action.filterBy },
			};
		case SHOW_MORE_FLIGHTS:
			return { ...quotation, flightsPageSize: quotation.flightsPageSize + FLIGHTS_PAGE_SIZE };
		case SHOW_ALL_ACTIVITIES:
			return { ...quotation, showAllActivities: true };
		case RESET_FLIGHTS_FILTER: {
			const filtersValues = action.filtersValues;
			return {
				...quotation,
				flightsPageSize: quotation.taggedFlightsNumber,
				filterFlightsBy: {
					...quotation.filterFlightsBy,
					stop: undefined,
					departureAirport: undefined,
					arrivalAirport: undefined,
					airline: undefined,
					luggage: undefined,
					upgradePrice: filtersValues.upgradePrice,
					inboundDuration: filtersValues.inboundDuration.max,
					outboundDuration: filtersValues.outboundDuration.max,
					inboundTakeOffTime: {
						min: filtersValues.inboundTakeOffTime.min,
						max: filtersValues.inboundTakeOffTime.max,
					},
					outboundTakeOffTime: {
						min: filtersValues.outboundTakeOffTime.min,
						max: filtersValues.outboundTakeOffTime.max,
					},
				},
			};
		}
		case PRE_BOOK_REQUEST:
		case HOTEL_ONLY_PREBOOK_REQUEST: {
			return {
				...quotation,
				preBookStatus: PRE_BOOK_CODE_STATUS.LOADING,
				deltaBasePrice: 0,
				priceChange: {},
			};
		}
		case PRE_BOOK_SUCCESS: {
			// On souhaite masquer le message de changement de prix lorsque l'utilisateur revient à la page de quote.
			let newState = cloneDeep(quotation);
			const data = action.res.data;
			const preBookStatus = data.status;
			newState.preBookStatus = preBookStatus;
			newState.priceChange = {};
			newState.deltaBasePrice = 0;
			return newState;
		}
		case HOTEL_ONLY_PREBOOK_SUCCESS: {
			return { ...quotation, preBookStatus: PRE_BOOK_CODE_STATUS.SUCCESS };
		}
		case PRE_BOOK_FAILURE:
		case HOTEL_ONLY_PREBOOK_FAILURE: {
			return { ...quotation, preBookStatus: undefined, deltaBasePrice: 0, priceChange: {} };
		}
		case FETCH_FLIGHT_OPTIONS_REQUEST:
			return {
				...quotation,
				flightOptionsStatus: FLIGHT_OPTIONS_STATUS.LOADING,
				flightOptionsFlightCode: undefined,
			};
		case FETCH_FLIGHT_OPTIONS_FAILURE:
			return {
				...quotation,
				flightOptionsStatus: FLIGHT_OPTIONS_STATUS.ERROR,
				flightOptionsFlightCode: undefined,
			};
		case FETCH_FLIGHT_OPTIONS_SUCCESS: {
			const data = action.res.data;
			return {
				...quotation,
				flightOptionsStatus: data.status,
				flightOptionsFlightCode: data.flight,
				flightOptions: {
					...quotation.flightOptions,
					...(data.flightOptions && {
						[data.flight]: data.flightOptions,
					}),
				},
				transfers: data.transfers,
			};
		}
		case SHOW_MORE_ACCOMMODATIONS:
			return {
				...quotation,
				accommodationsPageSize: quotation.accommodationsPageSize + ACCOMMODATIONS_PAGE_SIZE,
			};
		case RESET_FLIGHTS_PAGE_SIZE:
			return {
				...quotation,
				flightsPageSize: quotation.taggedFlightsNumber,
			};
		case SHOW_FLEX_RATES_MODAL:
			return { ...quotation, isFlexRatesModalOpened: action.isFlexRatesModalOpened };
		case FETCH_DISTRIBUTIONS_REQUEST:
			return {
				...quotation,
				status: QUOTATION_CODE_STATUS.LOADING,
				distributions: [],
				quotationItems: [],
			};
		case FETCH_DISTRIBUTIONS_SUCCESS: {
			const data = action.res.data;

			return {
				...quotation,
				status: QUOTATION_CODE_STATUS.SUCCESS,
				distributions: formatDistributions(data),
				quotationItems:
					data?.quotationItems?.map(quotationItem => ({
						price: quotationItem?.price?.perStay?.net || 0,
						description: quotationItem?.description,
					})) || [],
			};
		}
		default:
			return quotation;
	}
};
