import PropTypes from "prop-types";
import { memo, useCallback, useEffect, useState, useContext } from "react";
import classNames from "classnames";
import { forceCheck } from "react-lazyload";
import camelCase from "lodash/camelCase";
import includes from "lodash/includes";
import { FormattedDate, FormattedMessage } from "react-intl";
import { FLAGSHIP_EVENT_KPIS, PRODUCTS_FILTERS_KEY, RESOLUTION } from "app/constants";
import { Tab, TabList, TabPanel, Tabs } from "react-tabs";
import {
	sendTagOnProductsFilter,
	sendTagOnProductsFilterOpen,
	sendTagOnProductsFilterRemove,
} from "app/utils/analytics";
import "./ProductsFilter.scss";
import Separator from "app/pages/.shared/Separator";
import IconArrowDown from "app/pages/.shared/static/icons/IconArrowDown";
import IconArrowUp from "app/pages/.shared/static/icons/IconArrowUp";
import IconFilterDestination from "app/pages/.shared/static/icons/IconFilterDestination";
import IconFilterTheme from "app/pages/.shared/static/icons/IconFilterTheme";
import IconFilterCalendar from "app/pages/.shared/static/icons/IconFilterCalendar";
import useClickAway from "app/utils/hooks/useClickAway";
import { EventCategory, HitType, useFlagship } from "@flagship.io/react-sdk";
import usePrevious from "app/utils/hooks/usePrevious";
import AppGlobalsContext from "app/AppGlobalsContext";

const ProductsFilter = ({
	productsFilters = {},
	visibleFilters = {},
	selectedFacets = {},
	productsActiveFilters = [],
	setProductsFilter = () => {},
	unsetProductsFilter = () => {},
	destinationFilterValues = [],
	topicFilterValues = [],
	monthsFilterValues = [],
	className,
	filtersRef = {},
	isStuck,
	scrollToListingHeader = () => {},
	isSticky = true,
	displayFilters = true,
	displayDestinationFilter = true,
}) => {
	const { resolution } = useContext(AppGlobalsContext);

	const isMobile = resolution === RESOLUTION.SMALL || resolution === RESOLUTION.MEDIUM;
	const [selectedTab, setSelectedTab] = useState(-1); // par defaut aucun panneau filtre ne doit être ouvert

	const { hit: fsHit } = useFlagship();

	const filterClassname = classNames("products-filters", className, {
		"products-filters--sticky": isSticky,
		"products-filters--stuck": isSticky && isStuck,
	});

	const previousSelectedTag = usePrevious(selectedTab);

	const handleSelect = useCallback(
		index => {
			setSelectedTab(selectedTab === index ? -1 : index); // si on reclic sur un onglet, on ferme le panneau
		},
		[selectedTab]
	);

	useEffect(() => {
		if (previousSelectedTag !== selectedTab) {
			forceCheck(); // forcer l'affichage des photos des produits filtrés après l'application d'un filtre
		}
	}, [selectedTab, previousSelectedTag]);

	useClickAway(filtersRef, () => {
		setSelectedTab(-1);
	});

	const isDestinationFilterActive = productsFilters.destination;

	const isMonthFilterActive = productsFilters.month;

	const isTopicFilterActive = productsFilters.topic;

	const isBadgeFilterActive = productsFilters.badge;

	const isDiscountPercentageFacetActive = productsFilters.discountPercentage;

	const percentageCount = selectedFacets.discountPercentage.true
		? selectedFacets.discountPercentage.true.length
		: 0;

	const percentageClassname = classNames({
		"products-filters__facet": true,
		"products-filters__facet--active": isDiscountPercentageFacetActive,
		"products-filters__facet--available": percentageCount > 0,
	});

	const isNewFacetActive = productsFilters.startAt;

	const newCount = selectedFacets.startAt.true ? selectedFacets.startAt.true.length : 0;

	const newClassname = classNames({
		"products-filters__facet": true,
		"products-filters__facet--active": isNewFacetActive,
		"products-filters__facet--available": newCount > 0,
	});

	let topicTabHeaderLabel = (
		<div className="products-filters__filter-name">
			<FormattedMessage id="listing.filter.theme.label" tagName="span" />
		</div>
	);

	if (isNewFacetActive) {
		topicTabHeaderLabel = (
			<div className="products-filters__filter-name">
				<FormattedMessage id="listing.filter.new" />
			</div>
		);
	} else if (productsFilters.topic) {
		topicTabHeaderLabel = (
			<div className="products-filters__filter-name">{productsFilters.topic}</div>
		);
	} else if (productsFilters.badge) {
		topicTabHeaderLabel = (
			<div className="products-filters__filter-name">{productsFilters.badge}</div>
		);
	} else if (isDiscountPercentageFacetActive) {
		topicTabHeaderLabel = (
			<div className="products-filters__filter-name">
				<FormattedMessage id="listing.filter.percentage" values={{ percentage: "50" }} />
			</div>
		);
	}

	let periodTabHeaderLabel = (
		<div className="products-filters__filter-name">
			<FormattedMessage id="listing.filter.calendrier.label" tagName="span" />
		</div>
	);

	if (isMonthFilterActive) {
		periodTabHeaderLabel = (
			<div className="products-filters__filter-name">
				<FormattedDate value={Number(productsFilters.month)} month="long" year="numeric">
					{monthLabel => <div>{monthLabel}</div>}
				</FormattedDate>
			</div>
		);
	}

	let destinationTabIcon = (
		<div className="products-filters__filter-control">
			<FormattedMessage id="general.filter" tagName="span" />
			<IconArrowDown height={15} width={15} />
		</div>
	);
	if (isDestinationFilterActive) {
		destinationTabIcon = (
			<div
				className="products-filters__filter-control products-filters__filter-control--active"
				onClick={e => {
					e.stopPropagation();
					unsetProductsFilter(PRODUCTS_FILTERS_KEY.DESTINATION);
					sendTagOnProductsFilterRemove("destination");
					scrollToListingHeader();
				}}
			>
				{!isMobile && <FormattedMessage id="general.delete" tagName="span" />}
				<i className="icon icon--circle-cross-transparent" />
			</div>
		);
	} else if (selectedTab === 0) {
		destinationTabIcon = (
			<div className="products-filters__filter-control">
				<FormattedMessage id="general.filter" tagName="span" />
				<IconArrowUp height={15} width={15} />
			</div>
		);
	}

	let topicTabIcon = (
		<div className="products-filters__filter-control">
			<FormattedMessage id="general.filter" tagName="span" />
			<IconArrowDown height={15} width={15} />
		</div>
	);
	if (
		isTopicFilterActive ||
		isBadgeFilterActive ||
		isNewFacetActive ||
		isDiscountPercentageFacetActive
	) {
		topicTabIcon = (
			<div
				className="products-filters__filter-control products-filters__filter-control--active"
				onClick={e => {
					e.stopPropagation();
					unsetProductsFilter(PRODUCTS_FILTERS_KEY.TOPIC);
					unsetProductsFilter(PRODUCTS_FILTERS_KEY.BADGE);
					unsetProductsFilter(PRODUCTS_FILTERS_KEY.START_AT);
					unsetProductsFilter(PRODUCTS_FILTERS_KEY.DISCOUNT_PERCENTAGE);
					sendTagOnProductsFilterRemove("theme");
					scrollToListingHeader();
				}}
			>
				{!isMobile && <FormattedMessage id="general.delete" tagName="span" />}
				<i className="icon icon--circle-cross-transparent" />
			</div>
		);
	} else if (selectedTab === 1) {
		topicTabIcon = (
			<div className="products-filters__filter-control">
				<FormattedMessage id="general.filter" tagName="span" />
				<IconArrowUp height={15} width={15} />
			</div>
		);
	}

	let periodTabIcon = (
		<div className="products-filters__filter-control">
			{!isMobile && <FormattedMessage id="general.filter" tagName="span" />}
			<IconArrowDown height={15} width={15} />
		</div>
	);
	if (isMonthFilterActive) {
		periodTabIcon = (
			<div
				className="products-filters__filter-control products-filters__filter-control--active"
				onClick={e => {
					e.stopPropagation();
					unsetProductsFilter(PRODUCTS_FILTERS_KEY.MONTH);
					sendTagOnProductsFilterRemove("calendar");
					scrollToListingHeader();
				}}
			>
				{!isMobile && <FormattedMessage id="general.delete" tagName="span" />}
				<i className="icon icon--circle-cross-transparent" />
			</div>
		);
	} else if (selectedTab === 2) {
		periodTabIcon = (
			<div className="products-filters__filter-control">
				<FormattedMessage id="general.filter" tagName="span" />
				<IconArrowUp height={15} width={15} />
			</div>
		);
	}

	const destinationTabClass = classNames({
		"products-filters__header": true,
		"products-filters__header--active": isDestinationFilterActive,
	});

	const topicTabClass = classNames({
		"products-filters__header": true,
		"products-filters__header--active":
			isTopicFilterActive ||
			isBadgeFilterActive ||
			isNewFacetActive ||
			isDiscountPercentageFacetActive,
	});

	const periodTabClass = classNames({
		"products-filters__header": true,
		"products-filters__header--active": isMonthFilterActive,
	});

	const destinationFilterValue =
		productsFilters.destination === "listing.filter.destination.multi.destinations.label" ? (
			<FormattedMessage id="listing.filter.destination.multi.destinations.label" />
		) : (
			productsFilters.destination
		);

	return displayFilters ? (
		<div className={filterClassname} data-testid="products-filters" ref={filtersRef}>
			<Tabs
				className="products-filters__container"
				selectedIndex={selectedTab}
				onSelect={handleSelect}
			>
				<TabList className={["products-filters__headers", "react-tabs__tab-list"]}>
					{displayDestinationFilter && (
						<Tab
							className={[destinationTabClass, "react-tabs__tab"]}
							onClick={() => {
								unsetProductsFilter(PRODUCTS_FILTERS_KEY.DESTINATION);
								sendTagOnProductsFilterOpen("destination");
								fsHit.sendMultiple([
									{
										type: HitType.EVENT,
										category: EventCategory.ACTION_TRACKING,
										action:
											FLAGSHIP_EVENT_KPIS.CLICK_ON_LISTING_FILTER_DESTINATION,
										label: isStuck ? "isStuck" : "",
									},
									{
										type: HitType.EVENT,
										category: EventCategory.ACTION_TRACKING,
										action: FLAGSHIP_EVENT_KPIS.CLICK_ON_LISTING_FILTER_ALL,
										label: isStuck ? "isStuck" : "",
									},
								]);
							}}
						>
							<div className="products-filters__header-content">
								<IconFilterDestination className="products-filters__filter-icon" />
								<div className="products-filters__filter-name">
									{isDestinationFilterActive ? (
										destinationFilterValue
									) : (
										<FormattedMessage id="listing.filter.destination.label" />
									)}
								</div>
								{destinationTabIcon}
							</div>
						</Tab>
					)}
					<Tab
						className={[topicTabClass, "react-tabs__tab"]}
						onClick={() => {
							unsetProductsFilter(PRODUCTS_FILTERS_KEY.TOPIC);
							unsetProductsFilter(PRODUCTS_FILTERS_KEY.BADGE);
							unsetProductsFilter(PRODUCTS_FILTERS_KEY.START_AT);
							unsetProductsFilter(PRODUCTS_FILTERS_KEY.DISCOUNT_PERCENTAGE);
							sendTagOnProductsFilterOpen("theme");
							fsHit.sendMultiple([
								{
									type: HitType.EVENT,
									category: EventCategory.ACTION_TRACKING,
									action: FLAGSHIP_EVENT_KPIS.CLICK_ON_LISTING_FILTER_TOPIC,
									label: isStuck ? "isStuck" : "",
								},
								{
									type: HitType.EVENT,
									category: EventCategory.ACTION_TRACKING,
									action: FLAGSHIP_EVENT_KPIS.CLICK_ON_LISTING_FILTER_ALL,
									label: isStuck ? "isStuck" : "",
								},
							]);
						}}
					>
						<div className="products-filters__header-content">
							<IconFilterTheme className="products-filters__filter-icon" />
							{topicTabHeaderLabel}
							{topicTabIcon}
						</div>
					</Tab>
					<Tab
						className={[periodTabClass, "react-tabs__tab"]}
						onClick={() => {
							unsetProductsFilter(PRODUCTS_FILTERS_KEY.MONTH);
							sendTagOnProductsFilterOpen("calendar");
							fsHit.sendMultiple([
								{
									type: HitType.EVENT,
									category: EventCategory.ACTION_TRACKING,
									action: FLAGSHIP_EVENT_KPIS.CLICK_ON_LISTING_FILTER_CALENDAR,
									label: isStuck ? "isStuck" : "",
								},
								{
									type: HitType.EVENT,
									category: EventCategory.ACTION_TRACKING,
									action: FLAGSHIP_EVENT_KPIS.CLICK_ON_LISTING_FILTER_ALL,
									label: isStuck ? "isStuck" : "",
								},
							]);
						}}
					>
						<div className="products-filters__header-content">
							<IconFilterCalendar className="products-filters__filter-icon" />
							{periodTabHeaderLabel}
							{periodTabIcon}
						</div>
					</Tab>
				</TabList>

				{displayDestinationFilter && (
					<TabPanel
						className={[
							"products-filters__content",
							"products-filters__content--destination",
							"react-tabs__tab-panel",
						]}
						key={`destination-${selectedTab}`}
					>
						<ul
							className="products-filters__facets"
							data-testid="products-filters-facets"
						>
							{destinationFilterValues?.map(destination => {
								const destinationKey = camelCase(destination);
								const available = includes(visibleFilters.destination, destination);
								const active = includes(productsActiveFilters, destination);
								const count = selectedFacets.destination[destination]?.length;
								const facetClassName = classNames({
									"products-filters__facet": true,
									"products-filters__facet--active": active,
									"products-filters__facet--available": available,
								});

								return (
									count > 0 && (
										<li
											className={facetClassName}
											key={destinationKey}
											onClick={() => {
												setProductsFilter({
													[PRODUCTS_FILTERS_KEY.DESTINATION]: destination,
												});
												handleSelect(-1); // fermeture de l'onglet
												scrollToListingHeader();
												sendTagOnProductsFilter("destination", destination);
											}}
										>
											<div>
												{destination ===
												"listing.filter.destination.multi.destinations.label" ? (
													<FormattedMessage id="listing.filter.destination.multi.destinations.label" />
												) : (
													destination
												)}
											</div>
											<div>({count})</div>
										</li>
									)
								);
							})}
						</ul>
					</TabPanel>
				)}

				<TabPanel
					className={classNames(
						[
							"products-filters__content",
							"products-filters__content--topic",
							"react-tabs__tab-panel",
						],
						{
							"products-filters__content--topic--pos-1": !displayDestinationFilter,
						}
					)}
					key={`topic-${selectedTab}`}
				>
					<div className="products-filters__facets">
						{topicFilterValues?.map(topic => {
							const topicKey = camelCase(topic);
							const available = includes(visibleFilters.topic, topic);
							const active = includes(productsActiveFilters, topic);
							const count = selectedFacets.topic[topic]?.length;
							const facetClassName = classNames({
								"products-filters__facet": true,
								"products-filters__facet--active": active,
								"products-filters__facet--available": available,
							});

							return (
								count > 0 && (
									<div
										className={facetClassName}
										key={topicKey}
										onClick={() => {
											unsetProductsFilter(PRODUCTS_FILTERS_KEY.START_AT);
											setProductsFilter({
												[PRODUCTS_FILTERS_KEY.TOPIC]: topic,
											});
											handleSelect(-1); // fermeture de l'onglet
											scrollToListingHeader();
											sendTagOnProductsFilter("theme", topic);
										}}
									>
										<div>{topic}</div>
										<div>({count})</div>
									</div>
								)
							);
						})}

						{(newCount > 0 || percentageCount > 0) && <Separator design="middle" />}

						{newCount > 0 && (
							<div
								className={newClassname}
								onClick={() => {
									unsetProductsFilter(PRODUCTS_FILTERS_KEY.BADGE);
									unsetProductsFilter(PRODUCTS_FILTERS_KEY.TOPIC);
									setProductsFilter({
										[PRODUCTS_FILTERS_KEY.START_AT]: "true",
									});
									handleSelect(-1); // fermeture de l'onglet
									scrollToListingHeader();
									sendTagOnProductsFilter("theme", "nouveautes");
								}}
							>
								<FormattedMessage id="listing.filter.new" /> ({newCount})
							</div>
						)}

						{percentageCount > 0 && (
							<div
								className={percentageClassname}
								onClick={() => {
									unsetProductsFilter(PRODUCTS_FILTERS_KEY.START_AT);
									unsetProductsFilter(PRODUCTS_FILTERS_KEY.BADGE);
									unsetProductsFilter(PRODUCTS_FILTERS_KEY.TOPIC);
									setProductsFilter({
										[PRODUCTS_FILTERS_KEY.DISCOUNT_PERCENTAGE]: "true",
									});
									handleSelect(-1); // fermeture de l'onglet
									scrollToListingHeader();
									sendTagOnProductsFilter("theme", "50%");
								}}
							>
								<FormattedMessage
									id="listing.filter.percentage"
									values={{ percentage: "50" }}
								/>{" "}
								({percentageCount})
							</div>
						)}
					</div>
				</TabPanel>

				<TabPanel
					className={[
						"products-filters__content",
						"products-filters__content--period",
						"react-tabs__tab-panel",
					]}
					key={`period-${selectedTab}`}
				>
					{visibleFilters.month.length === 0 ? (
						<FormattedMessage id="listing.filter.no.period.facet.message" />
					) : (
						<div className="products-filters__facets">
							<div className="products-filters__months">
								<div className="products-filters__title">
									<FormattedMessage id="listing.filter.month.label" />
								</div>
								{monthsFilterValues?.map(month => {
									const monthKey = camelCase(month);
									const available = includes(visibleFilters.month, month);
									const active = includes(productsActiveFilters, month);
									const count = selectedFacets.month[month]?.length;
									const facetClassName = classNames({
										"products-filters__facet": true,
										"products-filters__facet--active": active,
										"products-filters__facet--available": available,
									});

									return (
										count > 0 && (
											<div
												className={facetClassName}
												key={monthKey}
												onClick={() => {
													setProductsFilter({
														[PRODUCTS_FILTERS_KEY.MONTH]: month,
													});
													handleSelect(-1); // fermeture de l'onglet
													scrollToListingHeader();
													sendTagOnProductsFilter("month", month);
												}}
											>
												<FormattedDate
													value={Number(month)}
													month="long"
													year="numeric"
												/>
												<div>({count})</div>
											</div>
										)
									);
								})}
							</div>
						</div>
					)}
				</TabPanel>
			</Tabs>
		</div>
	) : null;
};

ProductsFilter.propTypes = {
	setProductsFilter: PropTypes.func,
	scrollToListingHeader: PropTypes.func,
	unsetProductsFilter: PropTypes.func,
	visibleFilters: PropTypes.object,
	selectedFacets: PropTypes.object,
	productsActiveFilters: PropTypes.arrayOf(PropTypes.string),
	destinationFilterValues: PropTypes.arrayOf(PropTypes.string),
	productsFilters: PropTypes.object,
	topicFilterValues: PropTypes.arrayOf(PropTypes.string),
	monthsFilterValues: PropTypes.arrayOf(PropTypes.string),
	filtersRef: PropTypes.object,
	className: PropTypes.string,
	isStuck: PropTypes.bool,
	isSticky: PropTypes.bool,
	displayFilters: PropTypes.bool,
	displayDestinationFilter: PropTypes.bool,
};

export default memo(ProductsFilter);
